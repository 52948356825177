export const story = {
	// -- OUTSIDE the Bar ------------------
	"start": {
		"text": [
			"Looking back at where you came from, you are left only with the now. It is dark, but the portal you took still illuminates  the sky. There is no turning back now."
			],
		"pre-option": "none",
		"options": ["Look around"],
		"optiontarget": ["start2"],
		"speaker": require("../images/lightFlicker.gif"),
		"imgtype": "full",
		"key": 1,
		"back": "start"
	},
	"start2": {
		"text": [
			
			"You look around and see a faint light emanating from the dark abyss. Peering at the light, you just barely discern a neon sign in the distance that reads “The Devil’s Lounge”.",
			"A small voice calls out.",
			"Voice: Can you hear me?"
			],
		"pre-option": "none",
		"options": ["Walk towards the sign","Listen for the voice"],
		"optiontarget": ["startx", "voice"],
		"speaker": require("../images/lightFlicker.gif"),
		"imgtype": "full",
		"key": 1,
		"back": "start"
	},
	"voice": {
		"text": [
			
			"A glowing orb floats in your direction, hovering around you with interest.",
			"Voice: Can you hear me?"
			],
		"pre-option": "none",
		"options": ["Yes","Ignore the voice"],
		"optiontarget": ["voice2", "GameOver"],
		"speaker": require("../images/voice.gif"),
		"imgtype": "full",
		"key": 1,
		"back": "start"
	},
	"voice2": {
		"text": [
			
			"A heavenly voice speaks “Do you have the time?” The orb fades. The massive sign catches your eye again."
			],
		"pre-option": "none",
		"options": ["Walk towards the sign."],
		"optiontarget": ["startx"],
		"speaker": require("../images/voice.gif"),
		"imgtype": "full",
		"key": 1,
		"back": "start"
	},

	"startOG": {
		"text": [
			"This is purgatory. A light flickers nearby, casting long shadows that dance over the ground. Your eyes catch something through the darkness. "
			],
		"pre-option": "none",
		"options": ["Move closer."],
		"optiontarget": ["start2"],
		"speaker": require("../images/lightFlicker.gif"),
		"imgtype": "full",
		"key": 1,
		"back": "start"
	},
	"startx": {
		"text": [
	
			"As you move closer, letters emerge from the blur.", 
			"‘The Devil’s Lounge’"
			],
		"pre-option": "none",
		"options": ["Approach the Lounge"],
		"optiontarget": ["peephole"],
		"speaker": require("../images/DLexterior.gif"),
		"imgname": "lounge",
		"imgtype": "full",
		"key": 2,
		"back": "start"
	},

	"peephole": {
		"text": [
			"You make your way to the main entrance of the lounge. Your footsteps clap against the ground and echo into darkness.",
			"The peephole slides open, grinding against its metal frame.",
			"A piercing stare greets you.",
			"“What’s the password?”"
			],
		"options": [],
		"optiontarget": [],
		"speaker": require("../images/peephole.gif"),
		"imgtype": "full",
		"password": "DL0",
		"imgname": "peephole",
		"key": 2,
		"back": "start"
	},
	

	"DL0": {
		"text": [
			"You walk inside and are welcomed by the hum of old speakers. A dusty track crackles through a jukebox, reverberating around the dim, smoky room. "
			],
		"options": ["Approach the jukebox","Go to the Bar"],
		"optiontarget": ["Jukebox","Bar0"],
		"speaker": require("../images/newBarScene.gif"),
		"imgtype": "full",
		"key": 3,
		"back": "start"
	},
	"Jukebox": {
		"text": [
			"“When Hope Cries” by The Bones plays on the jukebox"

			],
		"options": ["Listen"],
		"optiontarget": ["Jukebox1"],
		"speaker": require("../images/jukebox.png"),
		"imgtype": "full",
		"modal":"record",
		"key": 4,
		"back": "DL0"
	},
	"Jukebox1": {
		"text": [
			"As you listen you flip through the available collection. You see a place to enter a code on the jukebox."

			],
		"options": ["Enter a Code"],
		"optiontarget": ["Jukebox2"],
		"speaker": require("../images/jukeboxBlur.png"),
		"imgtype": "full",
		"key": 4,
		"back": "DL0"
	},
	"Jukebox2": {
		"text": [
			

			],
		"options": ["Enter"],
		"optiontarget": ["JukeboxEnd"],
		"speaker": require("../images/numpad.gif"),
		"imgtype": "full",
		"password": "JukeboxEnd",
		"key": 4,
		"back": "DL0"
	},
	"JukeboxEnd": {
		"text": [
		
"You listen to the lyrics until the track ends. Released from the trance, you turn and view the room. A few groups of people sit chatting. Their conversations blur into an indistinguishable hum. On the other end of the room, the bartender stands polishing an apple. "

			],
		"options": ["Go to the Bar and take a seat"],
		"optiontarget": ["Bar0"],
		"speaker": require("../images/jukebox.png"),
		"imgtype": "full",
		"key": 4,
		"back": "DL0"
	},
	"Bar0": {
		"text": [
			"The barkeep leans casually against the bar.",
			"Barkeep: Welcome to the lounge. First time?"
			],
		"options": ["Yes","No"],
		"optiontarget": ["Bar1","Bar1x"],
		"speaker": require("../images/barkeep.jpg"),
		"imgname": "barkeep",
		"imgtype": "full",
		"key": 5,
		"back": "DL0"
	},



	"Bar1": {
		"text": [
			"He finishes polishing an apple and places it on the napkin in front of you.",
			"Barkeep: This one’s on the house."
			],
		"options": ["Bite the apple."],
		"optiontarget": ["Bar2"],
		"speaker": require("../images/bkapple.jpg"),
		"imgtype": "full",
		"imgname": "barkeep",
		"modal": "apple",
		"key": 6,
		"back": "Bar0"
	},

	"Bar1x": {
		"text": [
			"He finishes polishing an apple and places it on the napkin in front of you.",
			"Barkeep: Welcome back. This one’s on the house."
			],
		"options": ["Bite the apple."],
		"optiontarget": ["Bar2"],
		"speaker": require("../images/bkapple.jpg"),
		"imgtype": "full",
		"imgname": "barkeep",
		"key": 6,
		"back": "Bar0"
	},

	"Bar2": {
		"text": [
			"You take a bite and savor the sweetness; a rush of imagery floods your vision.",
			"You furrow your brow.",
		
	
			],
		"options": ["Ask about the apples"],
		"optiontarget": ["Bar2x"],
		"speaker": require("../images/appleBite.png"),
		"imgtype": "full",
		"imgname": "apple",
		"key": 7,
		"back": "Bar0"
	},

	"Bar2x": {
		"text": [
			"You: Apples in purgatory?",
			"The Barkeep grins.",
			"Barkeep: Yes, we harvest those from the tree out back.",
			"He points toward a door.",
			"Barkeep: Can you see it?"
			],
		"options": ["You nod."],
		"optiontarget": ["Door"],
		"speaker": require("../images/appleBite.png"),
		"imgtype": "full",
		"imgname": "apple",
		"key": 7,
		"back": "Bar0"
	},

	"Door": {
		"text": [
			"Barkeep: You must have business in Eden..."
			],
		"options": ["Continue chatting","Go through the door."],
		"optiontarget": ["Bar3","Eden"],
		"speaker": require("../images/door.gif"),
		"imgtype": "full",
		"imgname": "door",
		"key": 8,
		"back": "Bar0"
	},
	"Bar3": {
		"text": [		
			"Barkeep: Well, don’t let me keep you."
			],
		"options": ["Continue chatting","Go through the door"],
		"optiontarget": ["Bar4","Eden"],
		"speaker": require("../images/barkeep.jpg"),
		"imgname": "barkeep",
		"imgtype": "full",
		"key": 9,
		"back": "Bar0"
	},
	"Bar4": {
		"text": [
			"You: One more apple won't hurt. Plus, I've got the time.",
			"Barkeep: That's true, with these apples at least.",
			"He glances at your briefcase",
			"Barkeep: That briefcase looks familiar. Have you ever heard of a man named Cold?"
			],
		"options": ["Yes","No (Go through the door)"],
		"optiontarget": ["Bar5","Eden"],
		"speaker": require("../images/barkeep.jpg"),
		//"imgname": "barkeep",
		"imgtype": "full",
		"key": 10,
		"back": "Bar0"
	},
	"Bar5": {
		"text": [
			"You look intently into the apple in hand.",
			"You: Yes… Well, I've only heard stories.",
			"You share an understanding glance with the barkeep."
			],
		"options": ["Ask about Cold.","Go through the door"],
		"optiontarget": ["Bar6","Eden"],
		"speaker": require("../images/barkeep.jpg"),
		"imgname": "barkeep",
		"imgtype": "full",
		"key": 11,
		"back": "Bar0"
	},
	"Bar6": {
		"text": [
		
			"You: Did Cold come through here?",
			"Barkeep: Well, yes, but that was many situations ago. He could be anywhere now...",
			"He points to the door again."
			],
		"options": ["End conversation","Go through the door"],
		"optiontarget": ["Bar7","Eden"],
		"speaker": require("../images/barkeep.jpg"),
		"imgname": "barkeep",
		"imgtype": "full",
		"key": 12,
		"back": "Bar0"
	},
	"Bar7": {
		"text": [
			 "You: You wouldn’t mind if I took a look back there, would you?",
			"Barkeep: The door calls to you.",
			"You stand up to leave, placing a candle on the bar. ",
			"You: Thank you, friend." ,
			"You walk to the door."
			],
		"options": ["Go through the door"],
		"optiontarget": ["Eden"],
		"speaker": require("../images/door.gif"),
		"imgname": "door",
		"imgtype": "full",
		"key": 13,
		"back": "Bar0"
	},
	"Eden": {
		"text": [
			"Stepping through the door feels like falling—your stomach drops, and the world shifts around you."
			],
		"options": ["Keep falling"],
		"optiontarget": ["Falling0"],
		"speaker": require("../images/newFalling.gif"),
		"imgtype": "full",
		"key": 14,
		"back": "Bar0"
	},
	
	"Falling0": {
		"text": [
			"You keep falling."
			],
		"options": ["Keep falling"],
		"optiontarget": ["Falling"],
		"speaker": require("../images/newFalling2.gif"),
		"imgname": "fall2",
		"imgtype": "full",
		"key": 14,
		"back": "Bar0"
	},
	"Falling": {
		"text": [
			"You keep falling."
			],
		"options": ["Keep falling"],
		"optiontarget": ["Falling1"],
		"speaker": require("../images/falling.gif"),
		"imgtype": "full",
		"imgname": "falldot",
		"key": 14,
		"back": "Falling0"
	},
	"Falling1": {
		"text": [
			"You keep falling."
			],
		"options": ["Keep falling"],
		"optiontarget": ["Falling2"],
		"speaker": require("../images/falling2.gif"),
		"imgtype": "full",
		"imgname": "falldot2",
		"key": 14,
		"back": "Falling0"
	},
	"Falling2": {
		"text": [
			"You keep falling."
			],
		"options": ["Keep falling"],
		"optiontarget": ["EdenX"],
		"speaker": require("../images/falling3.gif"),
		"imgtype": "full",
		"imgname": "falldot3",
		"key": 14,
		"back": "Falling0"
	},
	"EdenX": {
		"text": [
			
			"You emerge into a forest, its trees glowing faintly, their leaves reflecting light off jagged black rock."
			
			],
		"options": ["Take a deep breath","Listen intently"],
		"optiontarget": ["EdenXBreathe","EdenXListen"],
		"speaker": require("../images/edenEntryway.gif"),
		"imgname": "entryway",
		"imgtype": "full",
		"key": 14,
		"back": "Falling0"
	},
	"EdenXBreathe": {
		"text": [
			
		
			"The air is heavy, fragrant with earth and something metallic."
			
			],
		"options": ["Continue forward","Listen intently"],
		"optiontarget": ["Eden1","EdenXListen"],
		"speaker": require("../images/edenEntryway.gif"),
		"imgname": "entryway",
		"imgtype": "full",
		"key": 14,
		"back": "EdenX"
	},

	"EdenXListen": {
		"text": [
			
			
		
			"A faint breeze stirs the branches, and with it, glowing orbs drift lazily through the air. A school of them passes by, their light soft and pulsating, casting an otherworldly glow over the forest floor."
			],
		"options": ["Continue forward","Take a deep breath"],
		"optiontarget": ["Eden1","EdenXBreathe"],
		"speaker": require("../images/edenEntryway.gif"),
		"imgname": "entryway",
		"imgtype": "full",
		"key": 14,
		"back": "EdenX"
	},

	"Eden1": {
		"text": [
			"Ahead, a stone staircase winds its way up a hill."
			],
		"options": ["Approach the staircase."],
		"optiontarget": ["Eden1x"],
		"speaker": require("../images/newWalkingStairs.gif"),
		"imgname": "stairs",
		"imgtype": "full",
		"key": 15,
		"back": "EdenX"
	},
	"Eden1x": {
		"text": [
			"At the top, a glowing figure waits, perched like a sentinel.",
			"You’re drawn to it, needing direction in this strange new landscape."
	
			],
		"options": ["Approach the Spirit"],
		"optiontarget": ["Eden1x2"],
		"speaker": require("../images/forgeClose.gif"),
		"imgtype": "full",
		"key": 15,
		"back": "EdenX"
	},
	"Eden1x2": {
		"text": [
			
			"Spirit: Welcome to the Forge.",
			"Its voice is melodic, reverberating through the trees."
			],
		"options": ["Inquire","Approach the Forge"],
		"optiontarget": ["Eden2","TravelerSkip"],
		"speaker": require("../images/forgeClose.gif"),
		"imgtype": "full",
		"key": 15,
		"back": "EdenX"
	},
	"Eden1OLD": {
		"text": [
			"Ahead, a stone staircase winds its way up a hill. At the top, a glowing figure waits, perched like a sentinel. You’re drawn to it, needing direction in this strange new landscape.",
			"Spirit: Welcome to the Forge.",
			"Its voice is melodic, reverberating through the trees."
			],
		"options": ["Question the Spirit"],
		"optiontarget": ["Eden2"],
		"speaker": require("../images/forgeClose.gif"),
		"imgtype": "full",
		"key": 15,
		"back": "EdenX"
	},
	"Eden2": {
		"text": [
			"You: What are you?",
			"Spirit: I am the Spirit of the Forge. I have been anticipating your arrival."
			],
		"options": ["Inquire further","Approach the Forge"],
		"optiontarget": ["Eden3","Traveler"],
		"speaker": require("../images/forgeClose.gif"),
		"imgtype": "full",
		"key": 16,
		"back": "EdenX"
	},
	"Eden3": {
		"text": [
	
			"You: What’s the Forge?",
			"Spirit: A workshop built on a rock formation that was created at the dawn of Eden. The Forge is a part of purgatory. It is where resources can be mined and refined into usable crystals and relics. You will need them on your journey."
			],
		"options": ["Inquire further", "Approach the Forge."],
		"optiontarget": ["Eden4","Traveler"],
		"speaker": require("../images/forgeClose.gif"),
		"imgtype": "full",
		"key": 17,
		"back": "EdenX"
	},
	"Eden4": {
		"text": [
			
			"You: Who built all this?",
			"Spirit: Effort. One of the Timeless.",
			"It pauses, as though considering whether to share more. ",
			"Spirit of the Forge: I could tell you about them, if you’re interested."

			],
		"options": ["Ask about the timeless.."],
		"optiontarget": ["Traveler"],
		"speaker": require("../images/forgeClose.gif"),
		"imgtype": "full",
		"key": 18,
		"back": "EdenX"
	},
	"TravelerSkip": {
		"text": [
			"Before you can approach, a man steps out from the shadows of the Forge. The orbs follow in his wake, trailing behind him like companions. His movements are deliberate, as though time weighs on him more heavily than it does on anything else in this place.",
"The traveler halts in front of you, his eyes scanning your face. He frowns slightly, as though trying to place you.",
"Traveler: I like your briefcase."
			],
		"options": ["Respond"],
		"optiontarget": ["Trav1"],
		"speaker": require("../images/travelerEmerge.gif"),
		"imgname": "emergeSkip",
		"imgtype": "full",
		"key": 19,
		"back": "EdenX"
	},
	"Traveler": {
		"text": [
			"Before the spirit can continue, a man steps out from the shadows of the Forge. The orbs follow in his wake, trailing behind him like companions.",
"The traveler halts in front of you, his eyes scanning your face. He frowns slightly, as though trying to place you.",
"Traveler: I like your briefcase. "
			],
		"options": ["Respond"],
		"optiontarget": ["Trav1"],
		"speaker": require("../images/travelerEmerge.gif"),
		"imgname": "emerge2",
		"imgtype": "full",
		"key": 19,
		"back": "EdenX"
	},
	"Trav1": {
		"text": [
		"You: Thanks. I picked it up on my way here.",
		"The Traveler inspects the worn edges.",
		"The Traveler turns and walks with urgency away from the forge. ",
		"An orb leaves the traveler and begins inspecting you."

			],
		"options": ["Inspect the orb"],
		"optiontarget": ["Trav2"],
		"speaker": require("../images/travelerEmerge.gif"),
		"imgtype": "full",
		"key": 20,
		"back": "Traveler"
	},
	"Trav1x": {
		"text": [
	"As you follow, you notice an orb hovering curiously by your shoulder. It meets you at your nose as you turn. ",
"You pick up the pace to catch up with the traveler."
			],
		"options": ["Ask about the orbs"],
		"optiontarget": ["Trav2"],
		"speaker": require("../images/travelerFollow.gif"),
		"imgtype": "full",
		"key": 21,
		"back": "Traveler"
	},
	"Trav2": {
		"text": [
			"You: What are these things?",
			"Traveler: We call them Familiars. Some of them have names and others are nameless. The ones you see flying around have names. Up ahead you’ll see unidentified Familiars in jars. I use them to light the path."
			],
		"options": ["Adopt a Familiar","Follow the Traveler"],
		"optiontarget": ["Adopt","TP"],
		"speaker": require("../images/voice.gif"),
		"imgtype": "full",
		
		"key": 22,
		"back": "Traveler"
	},
	"Adopt": {
		"text": [
			"You reach your hand out and the familiar dances around it."
			
			],
		"options": ["Adopt a Familiar"],
		"optiontarget": ["TP"],
		"speaker": require("../images/fam.gif"),
		"imgtype": "full",
		"modal": "fam",
		"key": 22,
		"back": "Traveler"
	},
	"TP": {
		"text": [
			"As you follow the Traveler away from the forge, a path emerges through the brush of the trees."
			],
		"options": ["Continue following"],
		"optiontarget": ["TPx"],
		"speaker": require("../images/travelerFollow.gif"),
		"imgtype": "full",
		"key": 23,
		"back": "Traveler"
	},
	"TPx": {
		"text": [
			"The path is lined with jars, each holding an orb of light. They swirl lazily inside, casting flickering shadows across the massive trunks of trees."
			],
		"options": ["Continue following"],
		"optiontarget": ["TPxFollow"],
		"speaker": require("../images/travelerFollow.gif"),
		"imgtype": "full",
		"key": 23,
		"back": "Traveler"
	},
	"TPxFollow": {
		"text": [
			"You continue following the Traveler for what seems like an eternity."
			],
		"options": ["Continue following","Break the silence"],
		"optiontarget": ["TPxFollow","TPx1"],
		"speaker": require("../images/travelerFollow.gif"),
		"imgtype": "full",
		"key": 23,
		"back": "Traveler"
	},
	"TPx1": {
		"text": [
			
			//"Roots snake in and out of the ground, larger and more intricate than before.",
			"As you go to speak, the Traveler starts talking over you.",
			"Traveler: How did you get here?"
			],
		"options": ["Respond"],
		"optiontarget": ["TPx2"],
		"speaker": require("../images/travelerFollow.gif"),
		"imgtype": "full",
		"key": 23,
		"back": "Traveler"
	},
	"TPx2": {
		"text": [
			"You begin to explain but before you can finish, the traveler inspects your briefcase again. He whispers to himself, “the summoning circle.”"
			],
		"options": ["Respond"],
		"optiontarget": ["TP1"],
		"speaker": require("../images/travelerFollow.gif"),
		"imgtype": "full",
		"key": 23,
		"back": "Traveler"
	},
	"TP1": {
		"text": [
			"You: Yes, but something went wrong, and I destroyed my wand in the process. Also, I seem to have lost my chalk.",
			 "Traveler: There’s a mine up there.",
			 "He points to a cliff with a white face.",
			 "Traveler: We can stop there to gather rocks for the chalk. The wand will have to wait."
			],
		"options": ["Inquire further","Continue toward the mine"],
		"optiontarget": ["TP1x","Mines"],
		"speaker": require("../images/newMinesInDistance.gif"),
		"imgname": "minesDistance",
		"imgtype": "full",
		"key": 24,
		"back": "Traveler"
	},
	"TP1x": {
		"text": [
	
			"You: Can’t I make one from any of these trees?",
			"Traveler: No, the wood from these trees cannot channel magic. You’ll need to reach the Elder Tree for that. It’s far past my cottage, so it's best not to worry about it for now."
			],
		"options": ["Inquire further","Continue toward the mine"],
		"optiontarget": ["TP2","Mines"],
		"speaker": require("../images/travelerSideWalk.gif"),
		"imgname": "sidewalk",
		"imgtype": "full",
		"key": 24,
		"back": "Traveler"
	},
	"TP2": {
		"text": [
			"You: This rock that we need, how did it get here?",
			"The Traveler walks a few paces ahead, his gait steady but unhurried. He glances at the white peaks, then back at you, his brow furrowed slightly in thought."
			],
		"options": ["Repeat yourself","Continue toward the mine"],
		"optiontarget": ["TP2x1","Mines"],
		"speaker": require("../images/travelerSideWalk.gif"),
		"imgname": "sidewalk",
		"imgtype": "full",
		"key": 25,
		"back": "Traveler"
	},
	"TP2x1": {
		"text": [
				"Traveler: That’s a story for another time, though I am not sure of the specifics."
			],
		"options": ["Inquire further","Continue toward the mine"],
		"optiontarget": ["TP2x2","Mines"],
		"speaker": require("../images/travelerSideWalk.gif"),
		"imgname": "sidewalk",
		"imgtype": "full",
		"key": 25,
		"back": "Traveler"
	},
	"TP2x2": {
		"text": [
				"Traveler: While I’ve been here for long, I have barely scratched the surface. I’ve focused on understanding and identifying the Familiars."
			],
		"options": ["Inquire further","Continue toward the mine"],
		"optiontarget": ["TP3","Mines"],
		"speaker": require("../images/travelerSideWalk.gif"),
		"imgname": "sidewalk",
		"imgtype": "full",
		"key": 25,
		"back": "Traveler"
	},
	"TP3": {
		"text": [
			"You: Did someone teach you?",
"Traveler: The Shepherd. You likely encountered him on your way here; he can take on many forms. I still haven’t figured out who he is."

			],
		"options": ["Inquire further...","Continue toward the mine"],
		"optiontarget": ["TP4","Mines"],
		"speaker": require("../images/travelerSideWalk.gif"),
		"imgname": "sidewalk",
		"imgtype": "full",
		"key": 26,
		"back": "Traveler"
	},
	"TP4": {
		"text": [
			"You: What do you mean by that?",
"Traveler: The beings here are different—Timeless, or Timeless Beings of Permanence. Bit of a puzzle." ,
"He shrugs, turning his gaze back to the rocks ahead. "
			],
		"options": ["Continue toward the mine"],
		"optiontarget": ["Mines"],
		"speaker": require("../images/travelerSideWalk.gif"),
		"imgname": "sidewalk",
		"imgtype": "full",
		"key": 27,
		"back": "Traveler"
	},
	"TP5": {
		"text": [
"Traveler: But don’t worry about that yet. There are some things best left alone until you're ready."
			],
		"options": ["Continue toward the mine"],
		"optiontarget": ["Mines"],
		"speaker": require("../images/travelerSideWalk.gif"),
		"imgname": "sidewalk",
		"imgtype": "full",
		"key": 28,
		"back": "Traveler"
	},
	"Mines": {
		"text": [
			"As the forest continues to thin, you see peaks of white rock emerging from the landscape."
			],

		"options": ["Continue forward"],
		"optiontarget": ["MinesX"],
		"speaker": require("../images/mines.gif"),
		"imgtype": "full",
		"imgname": "mines",
		"key": 29,
		"back": "Traveler"
	},
	"MinesX": {
		"text": [
			"Traveler: Ah, you see that rockface? That’s what we’re mining. Only take what we need. Wake me when you’ve mined enough for six sticks of chalk. Ten kilograms should be enough. "
			
			],
		"options": ["Continue"],
		"optiontarget": ["Pickaxe"],
		"speaker": require("../images/mines.gif"),
		"imgname": "mines",
		"imgtype": "full",
		"key": 30,
		"back": "Mines"
	},
	"Pickaxe": {
		"text": [
			
			"Without missing a beat, the Traveler pulls a pickaxe from his pack, hands it to you, and then sits back on a nearby rock. He pulls out a small bowl of herbs, lights it, and a sweet, earthy aroma fills the air. He exhales a cloud of smoke, then leans back with a satisfied sigh."
			],
		"options": ["Get ready to mine"],
		"optiontarget": ["Mining"],
		"speaker": require("../images/mines.gif"),
		"imgname": "mines",
		"imgtype": "full",
		"modal": "pickaxe",
		"key": 30,
		"back": "Mines"
	},
	"Mining": {
		"text": [
			
			"You mine the rock."
			],
		"options": ["Start mining"],
		"optiontarget": ["Mining0"],
		"speaker": require("../images/mines.gif"),
		"imgtype": "full",
		"imgname": "mines",
		"modal": "mining",
		"key": 30,
		"back": "Mines"
	},
	"Mining0": {
		"text": [
			
			"Satisfied with your work, you take a rest."
			],
		"options": ["Rest"],
		"optiontarget": ["Mines1"],
		"speaker": require("../images/mines.gif"),
		"imgtype": "full",
		"imgname": "mines",
		"modal": "mineRest",
		"key": 30,
		"back": "Mines"
	},
	"Mines1": {
		"text": [
			"After you finish, you go to wake the Traveler. You realize you do not know his name.",
"You: Excuse me… friend? I have enough for the chalk.",
"Traveler: rustling around “That was fast. Also, you can call me Gideon.”",

			],
		"options": ["Ask where we're heading next."],
		"optiontarget": ["Mines1x"],
		"speaker": require("../images/travelerSmoke.gif"),
		"imgtype": "full",
		"key": 31,
		"back": "Mines"
	},
	"Mines1x": {
		"text": [
"You: Well then Gideon, where to next?", 
"The Traveler chuckles, picking up his pack and shouldering it with a practiced ease. You notice he lights another bowl of herbs as you start walking, the pipe smoke curling lazily into the sky. Familiars dance in the smoke."
			],
		"options": ["Ask about the herbs"],
		"optiontarget": ["Mines2"],
		"speaker": require("../images/smoke.gif"),
		"imgtype": "full",
		"modal": "pipe",
		"key": 32,
		"back": "Mines"
	},
	"Mines2": {
		"text": [
			"You: What is that stuff?",
			"Traveler: I call it Hopium. It grows near the trees. Smoking it here can give you some insight."
			],
		"options": ["Hopium?"],
		"optiontarget": ["Hopium"],
		"speaker": require("../images/travelerFollow2.gif"),
		"imgname": "followStairs",
		"imgtype": "full",
		"key": 33,
		"back": "Mines"
	},
	"Hopium": {
		"text": [
"You: Hopium?",
"Traveler: The name comes from one of the Timeless. Her name was Hope." , 
"He inhales deeply, the smoke swirling around him.", 
"Traveler: Some say Hope was entombed in a well here in purgatory. And the water from that well breathes life into Eden itself."
			],
		"options": ["Inquire further"],
		"optiontarget": ["Mines3"],
		"speaker": require("../images/travelerFollow2.gif"),
		"imgname": "followStairs",
		"imgtype": "full",
		"key": 34,
		"back": "Mines"
	},
	"Mines3": {
		"text": [
			"You: All of Eden?",
"Traveler: That’s how the story goes."
			],
		"options": ["Continue walking"],
		"optiontarget": ["HopiumPlant"],
		"speaker": require("../images/travelerFollow2.gif"),
		"imgname": "followStairs",
		"imgtype": "full",
		"key": 35,
		"back": "Mines"
	},

	"HopiumPlant": {
		"text": [
"As you continue walking, the path meets a stream. The Traveler points to some flowers by the stream.", 
"Traveler: That’s the Hopium plant.",
"He crouches down, plucking a few stems and offering one to you. ",

			],
		"options": ["Decline","Take the plant."],
		"optiontarget": ["HopiumPlant2","GameOver2"],
		"speaker": require("../images/travelerFollow2.gif"),
		"imgname": "followStairs",
		"imgtype": "full",
		"key": 36,
		"back": "Mines"
	},

	// wasted joke
	"HopiumPlant2": {
		"text": [
"Pick some for yourself. You might need it.",
"As you collect the flowers, the sound of water and rustling leaves creates a peaceful rhythm, a brief respite from the strange tension that seems to hang in the air." 
			],
		"options": ["Continue towards the cottage"],
		"optiontarget": ["Mines5"],
		"speaker": require("../images/hopium.png"),
		"imgtype": "full",
		"modal": "hopium",
		"key": 37,
		"back": "Mines"
	},
	"Mines4": {
		"text": [
			"You: Earlier, you asked what year it was when I left home… When did you leave Earth?",
			"The Traveler exhales a long plume of smoke, his gaze far away.",
			"Traveler: Not long before you. Which makes me wonder... What brings you here?"
			],
		"options": ["Explain why you came."],
		"optiontarget": ["Mines4p5"],
		"speaker": require("../images/travelerFollow2.gif"),
		"imgname": "followStairs",
		"imgtype": "full",
		"key": 38,
		"back": "Mines"
	},
	"Mines4p5": {
		"text": [
			
"You: I came because of an old legend. Our founder had discovered a form of magic that allows you to travel between realms. He set out with a goal. And when he never returned, we thought he had left us behind. Many of us tried to mimic his magic. I was the first to succeed. But arriving here I realize he must have succeeded.",
"Traveler: Hmmm... An old legend. Well, we have a lot to talk about."
			],
		"options": ["Ask what he means.."],
		"optiontarget": ["Mines5"],
		"speaker": require("../images/travelerFollow2.gif"),
		"imgname": "followStairs",
		"imgtype": "full",
		"key": 39,
		"back": "Mines"
	},
"Mines5": {
		"text": [
			//"You: What do you mean?",
"Traveler: For now, let’s focus on the task at hand. When we get back to my cottage, we will craft the chalk you need. Have you pressed ritual chalk before?"			],
		"options": ["Respond"],
		"optiontarget": ["Mines5x"],
		"speaker": require("../images/travelerFollow2.gif"),
		"imgname": "followStairs",
		"imgtype": "full",
		"key": 40,
		"back": "Mines"
	},
	"Mines5x": {
		"text": [
			//"You: What do you mean?",

"You: Yes but not from rock like this.",
"Traveler: I have a machine I’ve built that will make you a piece of chalk using that rock. When we get there, we just put it in the machine and it presses the chalk like this.",
"He holds up a piece of Ritual Chalk."
			],
		"options": ["Head along to the Cottage"],
		"optiontarget": ["Cottage"],
		"speaker": require("../images/ritualchalk.jpg"),
		"imgtype": "full",
		"key": 40,
		"back": "Mines"
	},
"Cottage": {
		"text": [
			"The rocks along the mountainside fade into the landscape as you follow the traveler. The forest around you begins to resemble the brush of the entryway.",
			"Then, in a clearing, the traveler’s cottage appears in the distance. The lower branches of a tree hide it from the path. It is a modest-sized home built of wood. A path of black rocks lead the way to the door."
			],
		"options": ["Approach the house"],
		"optiontarget": ["Cottage2"],
		"speaker": require("../images/cottageExterior.gif"),
		"imgname": "cottageOutside",
		"imgtype": "full",
		"key": 41,
		"back": "Mines"
	},
	"Cottage2": {
		"text": [
			"Traveler: Welcome to my home. Let me cook us something to eat while you get started on the chalk."
			],
		"options": ["Enter the cottage"],
		"optiontarget": ["Cottage2x"],
		"speaker": require("../images/cottageExterior.gif"),
		"imgname": "cottageOutside",
		"imgtype": "full",
		"key": 42,
		"back": "Cottage"
	},
	"Cottage2x": {
		"text": [
		
			"You enter the cottage; its interior is far larger than the exterior would have you believe.",
			"You spot what appears to be the chalk-making machine that the Traveler explained to you."
			],
		"options": ["Craft Ritual Chalk"],
		"optiontarget": ["Cottage4"],
		"speaker": require("../images/cottageInside.gif"),
		"imgname": "cottageInside",
		"imgtype": "full",
		"modal": "craft",
		"key": 42,
		"back": "Cottage"
	},
	"Cottage4": {
		"text": [
			"You walk over and pour your rock into the machine."
			],
		"options": ["Finish crafting"],
		"optiontarget": ["Cottage4x"],
		"speaker": require("../images/cottageInside.gif"),
		"imgname": "cottageInside",
		"imgtype": "full",
		"key": 43,
		"back": "Cottage"
	},
	"Cottage4x": {
		"text": [
			"After you finish crafting the chalk, you wander around the cabin."
			],
		"options": ["Inspect the bookshelf"],
		"optiontarget": ["CottageHD"],
		"speaker": require("../images/cottageInside.gif"),
		"imgname": "cottageInside",
		"imgtype": "full",
		"key": 43,
		"back": "Cottage"
	},
	"CottageHD": {
		"text": [
			"While inspecting the cottage you see a bookshelf with a picture of two people holding each other."

			],
		"options": ["Inquire"],
		"optiontarget": ["CottageHD1"],
		"speaker": require("../images/HD.gif"),
		"imgname": "picture",
		"imgtype": "full",
		"key": 43,
		"back": "Cottage"
	},
	"CottageHD1": {
		"text": [
			
			"You: I think I’ve seen this picture before. Who are they?",
			"The traveler picks up the frame and lets out a deep sigh.",
			"Travler: The years pile up like coats of paint. I barely recognize her anymore.",
			"He hands you the frame"
			],
		"options": ["To Be Continued..."],
		"optiontarget": ["start"],
		"speaker": require("../images/HD.gif"),
		"imgtype": "full",
		"key": 43,
		"back": "Cottage"
	},

	"GameOver": {
		"text": [
			"Your story has ended.",

			],
		"options": ["Turn the page"],
		"optiontarget": ["start"],
		"speaker": require("../images/book.gif"),
		"imgtype": "full",
		"imgname": "gameover",
		"key": 1,
		"back": "start"
	},

	"GameOver2": {
		"text": [
			"You accepted drugs from a stranger. Your story has ended.",

			],
		"options": ["Turn the page"],
		"optiontarget": ["Mines2"],
		"speaker": require("../images/book.gif"),
		"imgtype": "full",
		"imgname": "gameover",
		"key": 1,
		"back": "start"
	},


	}

/*
"Mines": {
		"text": [
			
			],
		"options": [" "],
		"optiontarget": ["Mines1"],
		"speaker": require("../images/mines.gif"),
		"imgtype": "full"
	},
*/

	/*




Mines of Eden












/“old legend” seems a bit of stretch if the founder lived within the main character’s lifetime. While it doesn’t say that specifically, from the beginning chat with the bartender, I was under the impression that he had met him/

/I wouldn’t mind some kind of transition or ending here. It’s a bit jarring to feel like you are in the middle of a conversation with a guy holding up chalk and then they are on the path. A “Let’s go” from the guy or “he put the chalk in his pocket and turned to leave” kind of thing/

Traveler’s Cottage


	*/