import './App.scss';
import React from 'react';
import Web3 from 'web3'; 
import { chainMap, enforceChain } from './tools/ChainTools.js'
import { tokens } from './configs/CultTokensConfig.js'
import { nfts } from './configs/CultNFTConfig.js'
import { degenesis, dialog, story } from './configs/CandleDegenesisConfig.js'

//import door from './imgs/door.gif'
//import famgif from './imgs/fam.gif'

import briefcase from './images/briefcase.png'
//import famgif from './imgs/famslow.gif'

//import door from './imgs/doorslowbig.gif'
import famgif from './imgs/famsmooth.gif'

import dustimg from './images/dust.png'
import karmacrystal from './images/karmacrystal.jpg'
import karmaimg from './images/karma.jpg'
import famcrystal from './images/famcrystal.jpg'
import figure from './imgs/figure.gif'

import edenEntryway from './images/edenEntryway.gif'
import storefront from './images/store-front.gif'


import record from './images/record.png'
import hopium from './images/hopium.png'
import pickaxe from './images/pickaxe.png'
import goggles from './images/goggles.jpg'
import pipe from './images/pipe.png'
import lantern from './images/lantern.png'

import apple from './images/apple.png'

import iconX from './images/icon--twitter.png'
import iconTelegram from './images/icon--telegram.png'
import iconDiscord from './images/discord.png'

import edenForge from './images/edenForge.png'

import rock from './images/rock.png'
import chalk from './images/chalk.png'

import rest from './images/sleepyFamiliar.gif'
import chalkMachine from './images/chalkMachine.jpg'

import pending from './images/pending.gif'
import sound__keychain from './sounds/keychain.wav'

import sound__pageOne from './sounds/pageOne.wav'
import sound__pageTwo from './sounds/pageTwo.wav'


import sound__menuMusic3 from './sounds/menuMusic3.mp3'
import sound__adopt from './sounds/adopt.wav'
import sound__click from './sounds/click.wav'
import sound__gameOver from './sounds/gameOver.wav'

import sound__crystal from './sounds/crystal.wav'
import sound__mining from './sounds/mining.wav'

import sound__hover from './sounds/hover.wav'

import tokenImg__fam from './imgs/fam1.png'

import resource__dust from './images/dust.png'
import resource__rock from './images/rock.jpg'
import resource__karma from './images/karma.jpg'

import crystal__fam from './images/famcrystal.jpg'
import crystal__karma from './images/karmacrystal.jpg'
import crystal__chalk from './images/chalk.jpg'
const MINING_DELAYS = [0,10000,1000,1]
const RES_NAMES = ["","Karma","Familiar Dust","Sacred Rock"]
const fs = (bal, dec = 18) => {
  let x = bal.toString()
  let digits, decimals
  let L = "0", R = "0"
  let output
  //console.log(x.length, dec)
  if (x.length > dec) {
    digits = x.length - dec
    L = x.slice(0,digits)
    R = x.slice(digits)
  } else {
    decimals = dec - x.length
    R = "0".repeat(decimals).concat(x)
  }
  output = L.concat(".").concat(R)
  output = new Intl.NumberFormat().format(output)
  return output
}
function App() {
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  // Connecting to Metamask
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  const [connected, setConnected] = React.useState(false)
  const [accounts, setAccounts] = React.useState([]);
  const [mmBtnText, setMMBtnText] = React.useState("Connect");

 
  // attached to the accountsChanged event listener
  // triggered once manually via connectMM
  function handleNewAccounts(newAccounts) {
    setAccounts(newAccounts);
  }

  // attached to the chainChanged event listener
  // triggered once manually via main hook
  // calls letItRip if the proper chain is selected
  function handleChainChange(chainId) {
    enforceChain("Fantom", letItRip)
    setMMBtnText("Connected")
    setIsModalConnectOpen(false)
    setIsMenuOpen(true)
    setIsBypassed(false)
  }

  // when triggered, connectMM requests the user connects to the dApp
  // if the user is already connected, or after the user connects,
  // connectMM sets the accounts state to the user's connected accounts,
  // and sets the connected state to true


  const connectMM = () => {
    
      if (!connected) {

        window.ethereum
          .request({ method: 'eth_requestAccounts' })
          .then((newAccounts) => {
            handleNewAccounts(newAccounts)
            setConnected(true)})
      }
      playKeychain()
  }

  
  React.useEffect(() => {
    if (connected) {
      window.ethereum.on('accountsChanged', handleNewAccounts);
      window.ethereum.on('chainChanged', handleChainChange);
      return () => {
        window.ethereum.on('accountsChanged', handleNewAccounts);
        window.ethereum.on('chainChanged', handleChainChange);
      };
    }
  }, [connected]);



  
  // --------- -------------------------------------------------------------------------------
  // MAIN HOOK -------------------------------------------------------------------------------
  // --------- -------------------------------------------------------------------------------

  // if a user is connected with at least one account,
  // trigger the handleChainChange function
  React.useEffect( () => {
    if (connected) {
        if (accounts.length > 0) {
          handleChainChange(window.ethereum.chainId)  
        }
      }
  }, [connected])



  // --------- -------------------------------------------------------------------------------

  // -- end of connecting to metamask
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----

  

  const [isDisabled, setDisabled] = React.useState(false);

  // this is a reference to the input field
  const theInputRef = React.createRef();

  // state for managing whether a transaction is pending
  const [isPending, setIsPending] = React.useState(false);
  const [pendingText, setPendingText] = React.useState("Pending Transaction");
  const web3 = new Web3(new Web3.providers.HttpProvider('https://rpc.ftm.tools'));





  const letItRip = () => {
    console.log("let it rip")


    goToDialog("start")

   

  }

  const getTransactionReceipt = (hash, callback= ()=>{}) => {
    console.log(hash)
    window.ethereum.request({
      method: "eth_getTransactionReceipt",
      params: [hash]
    }).then((res)=>{
      if (res == null) {
        getTransactionReceipt(hash, callback)
      }
      callback(res)
    }).catch((err)=>{
      console.log(err)
    })
  }

  const [livePage, setLivePage] = React.useState("home");

  const nav = (name) => {
    return () => {
      setLivePage(name);
    }
  }

  const [modalOn, setModalOn] = React.useState(false)
  const [activeModalPage, setActiveModalPage] = React.useState("")

  const setModal = (page) => {
    return () => {
      setModalOn(true)
      setActiveModalPage(page)
    }
  }

 
  var cult = new web3.eth.Contract(tokens["cult"]["abi"], tokens["cult"]["address"])
  var xcult = new web3.eth.Contract(tokens["xcult"]["abi"], tokens["xcult"]["address"])

const [activeDialogIndex, setActiveDialogIndex] = React.useState("start")
const [isDialogOpen, setIsDialogOpen] = React.useState(false)
const [checkedPreOptions, setCheckedPreOptions] = React.useState(true)
const passwordRef = React.createRef()
const goToDialog = (n) => {
  return () => {setActiveDialogIndex(n)}
}

const [isBCopen, setIsBCopen] = React.useState(false)

const [balRock, setBalRock] = React.useState(0)
const [balChalk, setBalChalk] = React.useState(0)
const toggleBC = () => {
  playKeychain()
  if (isBCopen) {
    setIsBCopen(false)
  } else {
    setIsBCopen(true)
    balanceOfItems()
    fam.methods.balanceOf(window.ethereum.selectedAddress)
      .call({from: window.ethereum.selectedAddress})
      .then((res)=> {
        console.log("token req", res)
        setFamBal(res)
      }).catch((err) => {
        console.log(err)
      })

      getResourceBalance(3, setBalRock)
      getCrystalBalance(11, setBalChalk)
  }
}


const [isMenuOpen, setIsMenuOpen] = React.useState(false)
const [isBypassed, setIsBypassed] = React.useState(false)
const showStory = () => {
  stopMenu()
  playKeychain()
  if (connected || isBypassed) {
    setIsMenuOpen(false)
    setIsLanderOpen(false)
    setIsDialogOpen(true)
  } else {
    alert("Please click Connect first")
  }
  
}

const [isModalConnectOpen, setIsModalConnectOpen] = React.useState(false)
const beginConnectFlow = () => {
  setIsModalConnectOpen(true)
}

const bypassConnect = () => {
  playKeychain()
  setIsModalConnectOpen(false)
  setIsMenuOpen(true)
  setMMBtnText("Connect")
  setIsBypassed(true)
}

const [isModalForgeOpen, setIsModalForgeOpen] = React.useState(false)
// ------------------------------------------------------------------------------------------
  // -----------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------
  // -----------------------------------------------------------------------------------------




const estimateGas = (contract, data, saveError, callback = ()=>{}) => {
   window.ethereum.request(
        {
          method: "eth_estimateGas",
          params: [
            {
              from: window.ethereum.selectedAddress,
              to: contract,     
              data: data,
            },
          ],
        }
      ).then((gasAmount)=>{
        callback()
      }).catch((err)=>{

        saveError(err.data.message)
        alert(err.data.message)
      })
}

const sendRequest = (contract, data, callback = ()=>{}) => {
  setIsPending(true)

  window.ethereum.request({
    method: "eth_sendTransaction",
    params: [
      {
        from: window.ethereum.selectedAddress,
        to: contract,
        value: 0,
        data: data,
      },
    ],}).then((hash)=>{
      getTransactionReceipt(hash,(receipt)=>{
        if (receipt != null) {
          setIsPending(false)
          callback()
        }
      })
    }).catch((err)=>{
      setIsPending(false)
    })
}

  var candle = new web3.eth.Contract(
    tokens["candle"]["abi"], 
    tokens["candle"]["address"])

  var resources = new web3.eth.Contract(
    nfts["resources"]["abi"],
    nfts["resources"]["address"]
    )

  const cultItems = new web3.eth.Contract(
  nfts["items"]["abi"],
  nfts["items"]["address"]
  ) 
  const crys = new web3.eth.Contract(
    nfts["crystals"]["abi"], 
    nfts["crystals"]["address"]) 

 // ------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------
const [minted_item1, set_minted_item1] = React.useState(0)
  const [minted_item2, set_minted_item2] = React.useState(0)
  const [minted_item11, set_minted_item11] = React.useState(0)
  const [minted_item12, set_minted_item12] = React.useState(0)
  const [minted_item13, set_minted_item13] = React.useState(0)
  const [minted_item21, set_minted_item21] = React.useState(0)
  const [minted_item22, set_minted_item22] = React.useState(0)
  const [minted_item31, set_minted_item31] = React.useState(0)

  const [minted_item101, set_minted_item101] = React.useState(0)
  const [minted_item102, set_minted_item102] = React.useState(0) 
  const [minted_item103, set_minted_item103] = React.useState(0)
  const [minted_item104, set_minted_item104] = React.useState(0)
  const [minted_item105, set_minted_item105] = React.useState(0)
  const [minted_item106, set_minted_item106] = React.useState(0)

  
    const getMintedAmount = (id, setState) => {
   cultItems.methods.getItem(id)
      .call({from: window.ethereum.selectedAddress})
      .then((res)=>{
        console.log(res["6"])
        setState(res["6"])
      }) 
  }

const approveCandle4Shop = (amt = "5000000000000000000000") => {
    let data = candle.methods.approve(nfts["items"]["address"], amt).encodeABI()
    window.ethereum.request(
    {
      method: "eth_sendTransaction",
      params: [
      {
        from: window.ethereum.selectedAddress,
        to: tokens["candle"]["address"],
        value: 0,
        data: data,
      },
      ],
    })
  
}

  
  const mint1155transaction = (itemId, cost, callback = () => {}) => {
  //  alert("You are now minting an item for "+ cost +" $CANDLE.")
      setIsPending(true)
      setPendingText("Minting an item for "+ cost + " $CANDLE.")

      let data = cultItems.methods.mintItem(itemId).encodeABI()

      window.ethereum.request(
      {
        method: "eth_sendTransaction",
        params: [
        {
          from: window.ethereum.selectedAddress,
          to: nfts["items"]["address"],
          value: 0,
          data: data,
        },
        ],
        
      }).then((res)=>{
        callback(res)
   
      }).catch((error) => {
        setPendingText("Mint transaction failed")
      })
  }

  const mint1155ingame = (itemId, cost, callback = () => {}) => {
    candle.methods.balanceOf(window.ethereum.selectedAddress)
      .call({from: window.ethereum.selectedAddress})
      .then((res)=>{
      
        if(Number(fs(res).replace(",","")) >= Number(cost)) {

          candle.methods.allowance(
            window.ethereum.selectedAddress,
            nfts["items"]["address"]
            ).call({from:window.ethereum.selectedAddress})
             .then((res)=> {
              if (Number(fs(res).replace(",","")) >= Number(cost)) {
                mint1155transaction(itemId,cost,
                  (hash) => {
                    getTransactionReceipt(hash, (receipt)=>{
                      if (receipt != null) {
                        callback(receipt)
                        setIsPending(false)
                      }
                    })
                  }
                  )
              } else {
                alert("In order to continue to mint, you must approve the spending of " + cost + " $CANDLE")
                setIsPending(true)
                setPendingText("Approving the shop to spend "+ cost + " $CANDLE.")
                let data = candle.methods.approve(nfts["items"]["address"], cost + "000000000000000000").encodeABI()
                window.ethereum.request({
                  method: "eth_sendTransaction",
                  params: [
                  {
                    from: window.ethereum.selectedAddress,
                    to: tokens["candle"]["address"],
                    value: 0,
                    data: data,
                  },
                  ],
                }).then((res)=>{
                  getTransactionReceipt(res,(receipt) => {
                    if (receipt != null) {
                        callback(receipt)
                        mint1155transaction(itemId,cost,(hash)=>{
                          getTransactionReceipt(hash,(receipt)=>{
                            if (receipt != null) {
                              callback(receipt)
                              setIsPending(false)
                            }
                          })
                        })
                      }
                  })
                  
                }).catch((error) => {
                    setPendingText("Approval transaction failed")
                  })
              }
             })
           } else { // user needs to buy candle
            alert("You do not have enough $CANDLE to purchase this item. Visit spooky.fi and add 0x208234F4f8B1bBEA59cfDc38666141654e851DCe as a custom token to purchase.")
           }
      }) 
  }

  const mint1155 = (itemId, cost = "5000", callback = () => {}) => {
    return () => {
      playKeychain()
      mint1155ingame(itemId, cost, callback)
    }
  }



  const [isStoreOpen, setIsStoreOpen] = React.useState(false)
const toggleStore = () => {
  console.log("toggling store", (isStoreOpen) ? "off" : "on")
  if (isStoreOpen) {
    setIsStoreOpen(false)
    setIsDialogOpen(true)
    console.log("store is toggled off")
  } else {
    setIsStoreOpen(true)
    setIsDialogOpen(false)
    console.log("store is toggled on")
    console.log("and the state is ", isStoreOpen)
  }
}

  React.useEffect(()=>{
    if (isStoreOpen) {
      getMintedAmount(1, set_minted_item1)
      getMintedAmount(2, set_minted_item2)
      getMintedAmount(11, set_minted_item11)
      getMintedAmount(12, set_minted_item12)
      getMintedAmount(13, set_minted_item13)
      getMintedAmount(21, set_minted_item21)
      getMintedAmount(22, set_minted_item22)
      getMintedAmount(31, set_minted_item31)

      getMintedAmount(101, set_minted_item101)
      getMintedAmount(102, set_minted_item102)
      getMintedAmount(103, set_minted_item103)
      getMintedAmount(104, set_minted_item104)
      getMintedAmount(105, set_minted_item105)
      getMintedAmount(106, set_minted_item106)

    }
  },[isStoreOpen])

  const showShop = () => {
    if (connected) {
      playKeychain()
      setIsStoreOpen(true)
      setIsMenuOpen(false)
      setIsDialogOpen(false)
      setIsLanderOpen(false)
    } else {
      alert("The shop is a web3 feature, please connect your wallet.")
    }
  }

  const closeShop = () => {
    if (connected) {
      playKeychain()
      setIsStoreOpen(false)
      setIsMenuOpen(true)
      setIsLanderOpen(true)
      setIsDialogOpen(false)
    }
  }

  const showMenu = () => {

    playKeychain()
     playMenu()
    setIsMenuOpen(true)
    setIsLanderOpen(true)
    setIsDialogOpen(false)
    setIsStoreOpen(false)
  }

  const [isLanderOpen, setIsLanderOpen] = React.useState(true)

  const press2start = () => {
    setIsLanderOpen(true)
    playKeychain()
    beginConnectFlow()
    playMenu()
  }

  const goBack =(x) => {

    return () => {
      playKeychain()
      setActiveDialogIndex(x)
    }
    
  }

  const ifHasImgNameGetIt = () => {
    return (story[activeDialogIndex.toString()].hasOwnProperty("imgname")) ? story[activeDialogIndex.toString()]["imgname"] : "base"
  }

  const ifHasModalGetIt = () => {
    return (story[activeDialogIndex.toString()].hasOwnProperty("modal")) ? story[activeDialogIndex.toString()]["modal"] : "base"
  }

// -----------------------------------------------------------------------------------
// MODALS


  const [isModalMineOpen, setIsModalMineOpen] = React.useState(false)
  const [isModalCraftOpen, setIsModalCraftOpen] = React.useState(false)
  const [isModalAppleOpen, setIsModalAppleOpen] = React.useState(false)
  const [isModalPickaxeOpen, setIsModalPickaxeOpen] = React.useState(false)
  const [isModalMineRestOpen, setIsModalMineRestOpen] = React.useState(false)
  const [isModalPipeOpen, setIsModalPipeOpen] = React.useState(false)
  const [isModalHopiumOpen, setIsModalHopiumOpen] = React.useState(false)

  const [isModalRecordOpen, setIsModalRecordOpen] = React.useState(false)

  const [isModalFamOpen, setIsModalFamOpen] = React.useState(false)

  const showModal = (modalName) => {
    playKeychain()
    if(modalName == "apple") {
      setIsModalAppleOpen(true)
    }

    if(modalName == "pickaxe") {
      setIsModalPickaxeOpen(true)
    }

    if(modalName == "mining") {
      setIsModalMineOpen(true)
    }
    if(modalName == "mineRest") {
      setIsModalMineRestOpen(true)
    }

    if(modalName == "craft") {
      setIsModalCraftOpen(true)
    }

    if(modalName == "pipe") {
      setIsModalPipeOpen(true)
    }
    if(modalName == "hopium") {
      setIsModalHopiumOpen(true)
    }

    if(modalName == "record") {
      setIsModalRecordOpen(true)
    }

    if(modalName == "fam") {
      playAdopt()
      setIsModalFamOpen(true)
    }
  }

 
  const xlastMinedResource = (callback = () => {}) =>{
    resources.methods.lastMinedResource(window.ethereum.selectedAddress)
      .call({from: window.ethereum.selectedAddress})
      .then((res)=>{
        callback(res)
      }).catch((err)=>{
        console.log(err)
      })
  }
    const [lastMinedResource, setLastMinedResource] = React.useState(0)

const startMiningBlock = (callback = () => {}) => {
    resources.methods.startMiningBlock(window.ethereum.selectedAddress)
      .call({from: window.ethereum.selectedAddress})
      .then((res)=>{
        callback(res)
      }).catch((err)=>{
        console.log(err)
      })
  }



  const stopMiningBlock = (callback = () => {}) => {
    resources.methods.stopMiningBlock(window.ethereum.selectedAddress)
      .call({from: window.ethereum.selectedAddress})
      .then((res)=>{
          callback(res)
      }).catch((err)=>{
        console.log(err)
      })
  }


  const getCurrentBlock = (callback = () => {}) => {
    web3.eth.getBlockNumber()
    .then((res)=>{
      console.log(res)
      callback(res)
    }).catch((err)=>{
      console.log(err)
    })
  }


  const restTimes = [0,10000,0,1]
  const miningTimes = [0,1,1,1]

  const checkMiningStatus = (callback = ()=>{}) => {
    startMiningBlock((startBlock) => {
      if (startBlock == 0) { // user is resting
        stopMiningBlock((stopBlock)=> {
          
          getCurrentBlock((currentBlock) => { 
   
            xlastMinedResource((resourceID)=> {
      
              if (Number(stopBlock) + restTimes[Number(resourceID)] <= Number(currentBlock)) {

                callback()

                
              } else {
                let d = Number(stopBlock) + restTimes[Number(resourceID)] - Number(currentBlock) 
                alert("You are still resting from your last mining session. Please wait " + d + " more blocks.")
                soundRef__mining.current.pause()
              }
            })
          })
        })
      } else { // user is mining
        // tell the user to rest first
        alert("You are mining. Please rest before attempting to mine again.")
        soundRef__mining.current.pause()
      } 
    })

  }

  const mineRockTransaction = () => {
    let data = resources.methods.mine(3).encodeABI()
      setIsPending(true)
      setPendingText("Mining Rock")
      window.ethereum.request(
        {
        method: "eth_sendTransaction",
        params: [
          {
            from: window.ethereum.selectedAddress,  
            to: nfts["resources"]["address"],
            value: 0,
            data: data,
          },
        ],
      }

        ).then((hash)=>{
          getTransactionReceipt(hash, (receipt)=>{
            if (receipt != null) {
              soundRef__mining.current.pause()
              setIsPending(false)
            }
          })
          
        }).catch((err)=>{
          setPendingText("Failed to mine rock")
          soundRef__mining.current.pause()
          console.log(err)
        })
  }

  const mineRock = () => {
    playMining()
    setActiveDialogIndex("Mining0")
    setIsModalMineOpen(false)

    // get the lastMinedResource for the user
    // get the stopMiningBlock for the user

    checkMiningStatus(mineRockTransaction)
    


    
  }

  const bypassMine = () => {
    playKeychain()
    setIsModalMineOpen(false)
    setActiveDialogIndex("Mining0")
  }

  const mineRest = () => {
    playKeychain()
    setIsModalMineRestOpen(false)
    setActiveDialogIndex("Mines1")

     let data = resources.methods.rest().encodeABI()
     setIsPending(true)
      setPendingText("Resting (this will mint your Rocks)")
    window.ethereum.request(
      {
        method: "eth_sendTransaction",
        params: [
          {
            from: window.ethereum.selectedAddress,
            to: nfts["resources"]["address"],
            value: 0,
            data: data,
          },
        ],
      }
    ).then((hash)=>{
     
          getTransactionReceipt(hash, (receipt)=>{
            if (receipt != null) {
             
              setIsPending(false)
            }
          })
        }).catch((err)=>{
          setIsPending(false)
          console.log(err)
        })
  }

  const bypassRest = () => {
    setIsModalMineRestOpen(false)
    setActiveDialogIndex("Mines1")
    playKeychain()
  }

  const craftChalk = () => {
    setIsModalCraftOpen(false)
    setActiveDialogIndex("Cottage4") 
    playCrystal()
    resources.methods.isApprovedForAll(
      window.ethereum.selectedAddress,
      nfts["crystals"]["address"]
      ).call({from: window.ethereum.selectedAddress})
       .then((res)=>{
        if (res) {
          let data = crys.methods.forge(11, 1).encodeABI()
          setIsPending(true)
          setPendingText("Crafting Chalk")
          window.ethereum.request({
            method: "eth_sendTransaction",
            params: [
              {
                from: window.ethereum.selectedAddress,
                to: nfts["crystals"]["address"],
                value: 0,
                data: data,
              },

            ],
        
          }).then((hash)=>{
            getTransactionReceipt(hash, (receipt)=>{
              if (receipt != null) {
                setIsPending(false)
              }
            })

          }).catch((err)=>{
            setPendingText("Forging failed")
          })
        } else {
          let approveReq = resources.methods.setApprovalForAll(nfts["crystals"]["address"], true).encodeABI()
          alert("You need to approve the use of your cult resources to mint Ritual Chalk.")
          setIsPending(true)
          setPendingText("Approving the use of your cult resources")
          window.ethereum.request(
      {
        method: "eth_sendTransaction",
        params: [
          {
            from: window.ethereum.selectedAddress,
            to: nfts["resources"]["address"],
            value: 0,
            data: approveReq,
          },

        ],
    
      }
    ).then((hash)=>{

      getTransactionReceipt(hash, (receipt)=>{
            if (receipt != null) {
              let data = crys.methods.forge(11, 1).encodeABI()
              setIsPending(true)
              setPendingText("Crafting Chalk")
              window.ethereum.request({
                method: "eth_sendTransaction",
                params: [
                  {
                    from: window.ethereum.selectedAddress,
                    to: nfts["crystals"]["address"],
                    value: 0,
                    data: data,
                  },
                ],
            
              }).then((res)=>{
                getTransactionReceipt(res, (receipt)=>{
                  if (receipt != null) {
                    setIsPending(false)
                  }
                })

              }).catch((err)=>{
                setPendingText("Forging failed")
              })
            }
          })
      
        
      }).catch((err)=>{
          setPendingText("Approval failed")
        })
        }
       })
    
    
      
    
     
  }

  const bypassCraft = () => {
    playKeychain()
    setIsModalCraftOpen(false)
    setActiveDialogIndex("Cottage4") 
  }


  const mintApple = () => {
    playKeychain()
    setIsModalAppleOpen(false)
    setActiveDialogIndex("Bar2")

    
    mint1155ingame(1, "1")
  }
  const bypassApple = () => {
    playKeychain()
    setIsModalAppleOpen(false)
    setActiveDialogIndex("Bar2")
  }

  const mintPickaxe = () => {
    playKeychain()
    setIsModalPickaxeOpen(false)
    setActiveDialogIndex("Mining")

    mint1155ingame(103, "1")
  }
  const bypassPickaxe = () => {
    playKeychain()
    setIsModalPickaxeOpen(false)
    setActiveDialogIndex("Mining")
  }

  const mintPipe = () => {
    playKeychain()
    setIsModalPipeOpen(false)
    setActiveDialogIndex("Mines2")

    mint1155ingame(105, "1")
  }
  const bypassPipe = () => {
    playKeychain()
    setIsModalPipeOpen(false)
    setActiveDialogIndex("Mines2")
  }

  const mintHopium = () => {
    playKeychain()
    setIsModalHopiumOpen(false)
    setActiveDialogIndex("Mines5")

    mint1155ingame(104, "50")
  }
  const bypassHopium = () => {
    playKeychain()
    setIsModalHopiumOpen(false)
    setActiveDialogIndex("Mines5")
  }

  const mintRecord = () => {
    playKeychain()
    setIsModalRecordOpen(false)
    setActiveDialogIndex("Jukebox1")

    mint1155ingame(101, "10")
  }
  const bypassRecord = () => {
    playKeychain()
    setIsModalRecordOpen(false)
    setActiveDialogIndex("Jukebox1")
  }


  const adoptFam = () => {
    playKeychain()
    setIsModalFamOpen(false)
    setActiveDialogIndex("TP")

    // logic for purchasing FAM on spooky goes here 
  }
  const bypassFam = () => {
    playKeychain()
    setIsModalFamOpen(false)
    setActiveDialogIndex("TP")
  }




// -----------------------------------------------------------------------------------
  const locOfColon = (str) => {
    return str.indexOf(":")
  }

  const replaceSpeakerWithStrongSpeaker = (str) => {
    let i = locOfColon(str)
   
    if (i == -1) {
      return str
    } else {
      return (
        <span>
          <strong>{str.substring(0,i)}</strong>
          <span>{str.substring(i,str.length)}</span>
        </span>
      )
    }
  }


const showForge = () => {
  crys.methods.balanceOf(
    window.ethereum.selectedAddress,
    11
    ).call({from: window.ethereum.selectedAddress})
  .then((res)=>{
    if (Number(res) > 0) {
      setIsModalForgeOpen(true)
      playKeychain()
    } else {
      alert("Complete the Campaign to access this feature")
    }
  })
  
}

const [itemBals,setItemBals] = React.useState([0,0,0,0,0,0,0])
const balanceOfItems = () => {
  console.log("Fetching item balances")
  cultItems.methods.balanceOfBatch(
    [
      window.ethereum.selectedAddress, 
      window.ethereum.selectedAddress, 
      window.ethereum.selectedAddress, 
      window.ethereum.selectedAddress, 
      window.ethereum.selectedAddress, 
      window.ethereum.selectedAddress, 
      window.ethereum.selectedAddress],
    [101, 102, 103, 104, 105, 106, 107]
    ).call({from: window.ethereum.selectedAddress})
     .then((res)=>{
      setItemBals(res)
     }).catch((error)=>{
      console.log(error)
     })
}

const getTokenBalance = (token, setState) => {
    token.methods.balanceOf(window.ethereum.selectedAddress)
      .call({from: window.ethereum.selectedAddress})
      .then((res)=> {
        console.log("token req", res)
        setState(res)
      }).catch((err) => {
        console.log(err)
      })
  }

  const getResourceBalance = (id, setState) => {
    resources.methods.balanceOf(window.ethereum.selectedAddress, id)
      .call({from: window.ethereum.selectedAddress})
      .then((res) => {
        console.log("resource req", res)
        setState(res)
      }).catch((err)=> {
        console.log(err)
      })
  }

   const [balRitualChalk, setBalRitualChalk] = React.useState(0)
  const getCrystalBalance = (id, setState) => {
    crys.methods.balanceOf(window.ethereum.selectedAddress, id)
      .call({from: window.ethereum.selectedAddress})
      .then((res) => {
        console.log("crystal req", res)
        setState(res)
      }).catch((err)=> {
        console.log(err)
      })
  }

  var fam = new web3.eth.Contract(
    tokens["fam"]["abi"], 
    tokens["fam"]["address"])

  const [famBal, setFamBal] = React.useState(0)


React.useEffect(
() => {
  console.log("itemBals has changed", itemBals)

}, [itemBals]
  )

  const soundRef__keychain = React.useRef(null)
  const soundRef__page = React.useRef(null)
  const soundRef__adopt = React.useRef(null)
  const soundRef__menu = React.useRef(null)
  const soundRef__gameOver = React.useRef(null)
  const soundRef__crystal = React.useRef(null)
  const soundRef__mining = React.useRef(null)
  const soundRef__hover = React.useRef(null)


  const playKeychain = () => {
    soundRef__keychain.current.play()
  }
  const playPageTurn = () => {
    soundRef__page.current.play()
  }

  const playAdopt = () => {
    soundRef__adopt.current.play()
  }

  const playMenu = () => {
    soundRef__menu.current.play()
    soundRef__menu.current.loop = true
    soundRef__menu.current.volume = 0.1
  }
  const stopMenu = () => {
    soundRef__menu.current.pause()
  }

  const playGameOver = () => {
    soundRef__gameOver.current.play()
  }
  const playCrystal = () => {
    soundRef__crystal.current.play()
  }

  const playMining = () => {
    soundRef__mining.current.play()
    soundRef__mining.current.loop = true
  }

  const playHover = () => {
    soundRef__hover.current.pause()
    soundRef__hover.current.play()
    soundRef__hover.current.currentTime = 0
    soundRef__hover.current.volume = 0.2

  }
  const stopHover = () => {
    

  }

const showFam = () => {
  crys.methods.balanceOf(
    window.ethereum.selectedAddress,
    11
    ).call({from: window.ethereum.selectedAddress})
  .then((res)=>{
    if (Number(res) > 0) {
      showModal("fam")
    } else {
      alert("Complete the Campaign to access this feature")
    }
  })
  

}

// -------------------------------------------------------------------
const [isDashboardShowing, setIsDashboardShowing] = React.useState(false)
const closeDash = () => {
    setIsDashboardShowing(false)
  }  

  // -- Dash Item Components ----

  const DashItem = (props)=>(
    <div className="dashboard__item" onClick={props.onClick}>
      <img src={props.src}/>
      <div className="dashboard__balance">{Number(props.bal)}</div>
    </div>
    )
  // -- balance states erc20 ----
  const [balCandle, setBalCandle] = React.useState(0)
  const [balCult, setBalCult] = React.useState(0)
  const [balXCult, setBalXCult] = React.useState(0)
  const [balFam, setBalFam] = React.useState(0)

  // -- balance states erc1155 ----
  const [balResources, setBalResources] = React.useState([0,0,0])
  const [balCrystals, setBalCrystals] = React.useState([0,0])
  const [balShrineCrystals, setBalShrineCrystals] = React.useState(0)

  // -- Mining Resources ----
  const [isMineShowing__Karma, setIsMineShowing__Karma] = React.useState(false)
  const [isMineShowing__Dust, setIsMineShowing__Dust] = React.useState(false)
  const [isMineShowing__Rock, setIsMineShowing__Rock] = React.useState(false)

  const mine = (index) => {
    return () => {
      if (index == 0) {
        setIsMineShowing__Karma(true)
        checkStatus()
      } else if (index == 1) {
        setIsMineShowing__Dust(true)
        checkStatusFD()
        checkFamBalance()
      } else if (index == 2) {
        setIsMineShowing__Rock(true)
        checkStatus()
      } else {
        alert("you are attempting to mine an invalid index")
      }
    }
    
  }

  const closeMine__Karma = () => {
    setIsMineShowing__Karma(false)
  }
  const closeMine__Dust = () => {
    setIsMineShowing__Dust(false)
  }
  const closeMine__Rock = () => {
    setIsMineShowing__Rock(false)
  }

  const MineModal = (props) => (
    <div className={"modal modal--" + props.name.replace(" ","-") + " modal-status--"+ props.status + " modal-statusFD--" + props.statusFD +" screen-showing--" + props.isShowing + " fambal--" + props.fambal}>
      <div className="close-modal" onClick={props.closeScreen}>
        <div>x</div>
      </div>
      <div className={props.name.replace(" ","-")}>
        <div className="mining__name">{props.name}</div>
        <div className="mining__bal">Balance: {Number(props.bal)}</div>
        <div className="mining__img"><img src={props.img}/></div>
        <div className="mining__stats">
          <div className="time">Mining Time: {props.time}</div>
          <div className="delay">Mining Delay: {props.delay}</div>
          <div className="weights">Mining Weights: {props.weights}</div>
          <div className="maxes">Mining Maxes: {props.maxes}</div>
          <div className="note">{props.note}</div>
          <div className="fdf-bal">Supply remaining: {props.fdfbal}</div>
          <div className="status-msg status--mining">You are finished mining. Now you must rest.</div>
          <div className="status-msg status--ready">You are ready to mine.</div>
          <div className="status-msg status--resting">You are resting.</div>
          <div className="status-msg status--fam">You need at least 1 Familiar to Mine</div>
        </div>
        <button className="mining__btn btn--mine" onClick={props.mine}>Mine</button>
        <button className="mining__btn btn--rest" onClick={props.rest}>Rest</button>
        <button className="mining__btn btn--refresh" onClick={props.refresh}>Refresh</button>
      </div>
    </div>



    )


  // -- Forging Resources ----
  const [isForgeShowing__Karma, setIsForgeShowing__Karma] = React.useState(false)
  const [isForgeShowing__Fam, setIsForgeShowing__Fam] = React.useState(false)
  const [isForgeShowing__Chalk, setIsForgeShowing__Chalk] = React.useState(false)
  const forge = (index) => {
   return () => {
      if (index == 0) {
        requestResources()
        requestCrystals()
        setIsForgeShowing__Karma(true)
      } else if (index == 1) {
        requestResources()
        requestCrystals()
       
        setIsForgeShowing__Fam(true)

      } else if (index == 2) {
        requestResources()
        requestCrystals()
        setIsForgeShowing__Chalk(true)
      } else {
        alert("you are attempting to forge an invalid index")
      }
    }
    
  }

  const closeForge__Karma = () => {
    setIsForgeShowing__Karma(false)
  }
  const closeForge__Fam = () => {
    setIsForgeShowing__Fam(false)
  }
  const closeForge__Chalk = () => {
    setIsForgeShowing__Chalk(false)
  }

  const ForgeModal = (props) => (
    <div className={"modal modal--forge modal--" + props.name.replace(" ","-") + " screen-showing--" + props.isShowing}>
      <div className="close-modal" onClick={props.closeScreen}>
        <div>x</div>
      </div>
      <div className={props.name.replace(" ","-")}>
        <div className="mining__name">{props.name}</div>
        <div className="mining__bal">Balance: {Number(props.bal)}</div>
        <div className="mining__img"><img src={props.img}/></div>
        <div className="mining__stats">
          <div className="xresource">Resource: <strong>{props.resource}</strong></div>
          <div className="rate">Conversion Rate: <strong>{props.rate}</strong></div>
          <div className="rate">Requires Shrine: <strong>{props.shrine}</strong></div>
          <div className="balres">{props.resource} Balance: <strong>{Number(props.resbal)}</strong></div>
          <div className="max">Your Max Craft Amount: <strong>{Number(props.resbal)/Number(props.rate)}</strong></div>
          <div className="active-shrine">Active Shrine: <strong>{props.activeShrineName}</strong></div>
          <div className="active-shrine-fee">Shrine Fee: <strong>{props.activeShrineFee} $CANDLE</strong></div>
          <div className="note">{props.note}</div>
        </div>
        <input ref={props.inputRef} />
        <button className="mining__btn btn--forge" onClick={props.forge}>Forge</button>  
        <button className="mining__btn btn--shrine btn--enter" onClick={props.enter}>Enter</button>
        <button className="mining__btn btn--shrine btn--leave" onClick={props.leave}>Leave</button>
      </div>
    </div>
    )

  const [status, setStatus] = React.useState("none")
  const [statusFD, setStatusFD] = React.useState("none")

  const checkStatus = () => {
    console.log("Checking status...")
    
    getStartMiningBlock((startBlock) =>{

      if (Number(startBlock) > 0) {
        console.log("startblock > 0",startBlock)
        // user is mining
        setStatus("mining")
        getLastMinedResource(setLastMinedResource)
      } else {

        // user is not mining
        getStopMiningBlock((stopBlock)=>{
          if (Number(stopBlock) > 0) {
            console.log("stopblock > 0",stopBlock)
            // user is resting
            getLastMinedResource((resourceID) => {
              setLastMinedResource(Number(resourceID))
              let delay = MINING_DELAYS[Number(resourceID)]

              getCurrentBlock((blockNumber)=>{
                if (delay + Number(stopBlock) <= Number(blockNumber)) {
                  console.log("user is done resting")
                  // user is done resting
                  setStatus("ready")
                } else {
                  console.log("user is resting")
                  // user is resting
                  setStatus("resting")
                }
              })

            })

          } else {
            // user is neither mining nor resting
            setStatus("newUser")
          }
        })
      }
    })
  }
  

  const checkStatusFD = () => {
     console.log("Checking status FD...")
     getStartMiningBlockFD((startBlock) =>{

      if (Number(startBlock) > 0) {
        console.log("startblock > 0",startBlock)
        // user is mining
        setStatusFD("mining")
      } else {
        // user is not mining
        getStopMiningBlockFD((stopBlock)=>{
          if (Number(stopBlock) > 0) {
            console.log("stopblock > 0",stopBlock)
            // user is resting
            let delay = MINING_DELAYS[2]

            getCurrentBlock((blockNumber)=>{
              if (delay + Number(stopBlock) <= Number(blockNumber)) {
                console.log("user is done resting")
                // user is done resting
                setStatusFD("ready")
              } else {
                console.log("user is resting")
                // user is resting
                setStatusFD("resting")
              }
            })
          } else {
            // user is neither mining nor resting
            setStatusFD("newUser")
          }
        })
      }
    })

    checkFDFbal()
  }

  const [fdfBal, setFdfbal] = React.useState(0)
  const checkFDFbal = () => {
    console.log("Checking FDF balance")
    resources.methods.balanceOf(
      nfts["famdustfarm"]["address"],
      2
    ).call({from: window.ethereum.selectedAddress})
     .then((res)=>{
      console.log("Checked FDF balance")
      setFdfbal(Number(res))
     }).catch((error)=>{
      console.log(error)
     })
  }

const bal20 = (token, callback = ()=>{}) => {
  token.methods.balanceOf(window.ethereum.selectedAddress)
    .call({from: window.ethereum.selectedAddress})
    .then((balance) => {
      callback(balance)
    })
}

const checkFamBalance = (callback = ()=>{}) => {
    bal20(fam,(balance)=>{
      setBalFam(balance)
      callback(balance)
    })
  }
const checkCandleBalance = (callback = ()=>{}) => {
    bal20(candle,(balance)=>{
      setBalCandle(balance)
      callback(balance)
    })
  }
const checkCultBalance = (callback = ()=>{}) => {
    bal20(cult,(balance)=>{
      setBalCult(balance)
      callback(balance)
    })
  }
const checkXcultBalance = (callback = ()=>{}) => {
    bal20(xcult,(balance)=>{
      setBalXCult(balance)
      callback(balance)
    })
  }
const checkTokenBalances= () => {
  checkFamBalance(
    checkCandleBalance(
      checkCultBalance(
        checkXcultBalance()
        )
      )
    )
}
 // -- balance requests erc1155 ----
  const requestResources = () => {
    balanceOfBatch(resources,[1,2,3],setBalResources)
  }
  const requestCrystals = () => {
    balanceOfBatch(crystals,[1,11],setBalCrystals)
    balanceOf(shrine_crystals,101,setBalShrineCrystals)
  }
// ------------------------------------------------------------------------------------
  // -- Status Checks ------------------------------------------------------

  const getLastMinedResource = (callback = ()=>{}) =>{
    resources.methods.lastMinedResource(window.ethereum.selectedAddress)
      .call({from: window.ethereum.selectedAddress})
      .then((res)=>{
        callback(res)
      }).catch((err)=>{
        console.log(err)
      })
  }

  const getStartMiningBlock = (callback = ()=>{}) => {
    resources.methods.startMiningBlock(window.ethereum.selectedAddress)
      .call({from: window.ethereum.selectedAddress})
      .then((res)=>{
        callback(res)
      }).catch((err)=>{
        console.log(err)
      })
  }

  const getStopMiningBlock = (callback = () => {}) => {
    resources.methods.stopMiningBlock(window.ethereum.selectedAddress)
      .call({from: window.ethereum.selectedAddress})
      .then((res)=>{
        callback(res)
      }).catch((err)=>{
        console.log(err)
      })
  }

  

  const getStartMiningBlockFD = (callback = () => {}) => {
    fdf.methods.startMiningBlock(window.ethereum.selectedAddress)
      .call({from: window.ethereum.selectedAddress})
      .then((res)=>{
        callback(res)
      }).catch((err)=>{
        console.log(err)
      })
  }

  const getStopMiningBlockFD = (callback = () => {}) => {
    fdf.methods.stopMiningBlock(window.ethereum.selectedAddress)
      .call({from: window.ethereum.selectedAddress})
      .then((res)=>{
        callback(res)
      }).catch((err)=>{
        console.log(err)
      })
  }
const balanceOfBatch = (erc1155, ids, setState) => {
  console.log("Fetching item balances")
  erc1155.methods.balanceOfBatch(
    Array(ids.length).fill(window.ethereum.selectedAddress),
    ids
    ).call({from: window.ethereum.selectedAddress})
     .then((res)=>{
      setState(res)
     }).catch((error)=>{
      console.log(error)
     })
}
var crystals = new web3.eth.Contract(nfts["crystals"]["abi"], nfts["crystals"]["address"])
  var shrine_crystals = new web3.eth.Contract(nfts["shrineCrystals"]["abi"], nfts["shrineCrystals"]["address"])
  const balanceOf = (erc1155, id, setState) => {
  console.log("Fetching item balance")
  erc1155.methods.balanceOf(
    window.ethereum.selectedAddress,
    id
    ).call({from: window.ethereum.selectedAddress})
     .then((res)=>{
      setState(res)
     }).catch((error)=>{
      console.log(error)
     })
}
  var fdf = new web3.eth.Contract(nfts["famdustfarm"]["abi"], nfts["famdustfarm"]["address"])
  const f = new Intl.NumberFormat('en-US')
  // ------------------------------------------------------------------------------------------
  // -- Mining and Rest functions for normal resources
  const startMining = (id) => {
    return () => {
      let data = resources.methods.mine(id).encodeABI()
      setIsPending(true)
      setPendingText("Mining " + RES_NAMES[id])
      sendRequest(nfts["resources"]["address"], data, ()=>{
        setIsPending(false)
        checkStatus()
      })
        
    }
  }

  const rest = () => {
    setIsPending(true)
    setPendingText("Resting, this will mint your pending resource")
    let data = resources.methods.rest().encodeABI()
    sendRequest(nfts["resources"]["address"],data, ()=> {
      setIsPending(false)
      checkStatus()
      requestResources()
    })
  }

  // ------------------------------------------------------------------------------------------
  // -- Mining and Rest functions for Familiar Dust 

  const startMiningFD = () => {
    console.log("Begin Mining")
    checkFamBalance((balance)=>{

      let bal = web3.utils.fromWei(balance, "ether")
      console.log("user has ",bal)
      if (bal >= 1) {
        let data = fdf.methods.mine().encodeABI()
        setIsPending(true)
        setPendingText("Mining Familiar Dust")
        sendRequest(nfts["famdustfarm"]["address"],data,()=>{
          setIsPending(false)
          checkStatusFD()
        })
      } else {
        alert("You have " + bal + " familiars, you need at least 1 to mine Dust.")
      }
    })
    
  }

  const restFD = () => {
    let data = fdf.methods.rest().encodeABI()
    setIsPending(true)
    setPendingText("Resting, this will mint your Familiar Dust")
    sendRequest(nfts["famdustfarm"]["address"],data,()=>{
      setIsPending(false)
      checkStatusFD()
      requestResources()
    })  
  }


  // -------------------------------------------------------------------
  // -- Forging Crystals ----------------------------------

  const crystalRef__karma = React.createRef()
  const crystalRef__chalk = React.createRef()

  const approve4all = (callback = () => {}) => {
    resources.methods.isApprovedForAll(window.ethereum.selectedAddress, nfts["crystals"]["address"])
      .call({from: window.ethereum.selectedAddress})
      .then((isApproved)=>{
        if (isApproved) {
          callback(isApproved)
        } else {
          let data = resources.methods.setApprovalForAll(nfts["crystals"]["address"], true).encodeABI()
          setPendingText("Approving the spending of your resources")
          sendRequest(nfts["resources"]["address"],data,callback)
        }
      })
  }

  const forgeTransaction = (id, callback = ()=>{}) => {
    let amt = (id == 1) ? crystalRef__karma.current.value : crystalRef__chalk.current.value
    let name = (id == 1) ? "Karma Crystal" : "Ritual Chalk"
    let data = crystals.methods.forge(id, amt).encodeABI()
    setIsPending(true)
    setPendingText("Forging " + amt + " " + name)
    sendRequest(nfts["crystals"]["address"], data, callback)
  }

  const startForging = (id) => {
    return () => {
      approve4all((res)=>{
        if (typeof(res) == "boolean") {
          if (res) {
            // forge 
            forgeTransaction(id, () => {
                setIsPending(false)
                requestResources()
                requestCrystals()
              })
          }
        } else {
          forgeTransaction(id, () => {
                setIsPending(false)
                requestResources()
                requestCrystals()
              })
        }
      })
    }
  }


  const [shrineError, setShrineError] = React.useState("")
  const startForgingShrine = () => {
    checkShrineConditions(
      () => {

        let data = asr.methods.enter(activeShrine + 1,101).encodeABI()
        estimateGas(nfts["AlphaShrineRouter"]["address"], data, setShrineError,
          () =>{
            setPendingText("Entering " + activeShrineName)
            sendRequest(nfts["AlphaShrineRouter"]["address"], data, ()=>{
              requestResources()
              balanceOf(shrine_crystals,101,setBalShrineCrystals)
            })
          }
          )
        
      }
      )
  }
  const finishForgingShrine = () => {
    let data = asr.methods.leave(activeShrine + 1).encodeABI()
    estimateGas(nfts["AlphaShrineRouter"]["address"], data, setShrineError,
      () => {
        setPendingText("Leaving " + activeShrineName) 
        sendRequest(nfts["AlphaShrineRouter"]["address"], data, () => {
          balanceOf(shrine_crystals,101,setBalShrineCrystals)
        })
      }
      )
  }

  const checkShrineConditions = (callback = ()=> {}) => {
    if (balResources[1] >= 32) {
      candle.methods.balanceOf(window.ethereum.selectedAddress)
        .call({from: window.ethereum.selectedAddress})
        .then((balance) => {
          let bal = web3.utils.fromWei(balance, "ether")
          if (bal >= Number(activeShrineFee)) {
            resources.methods.isApprovedForAll(window.ethereum.selectedAddress, nfts["AlphaShrineRouter"]["address"])
              .call({from: window.ethereum.selectedAddress})
              .then((isApproved)=>{
                if (isApproved) {
                  checkAllowanceThenApprove(callback)
                } else {
                  let data = resources.methods.setApprovalForAll(nfts["AlphaShrineRouter"]["address"], true).encodeABI()
                  setPendingText("Approving the spending of your resources")
                  sendRequest(nfts["AlphaShrineRouter"]["address"],data,()=>{
                    checkAllowanceThenApprove(callback)
                  })
                }
              })

          } else {
            alert("You need more $CANDLE")
          }
        })
    } else {
      alert("You need more Familiar Dust")
    }
  }
  const [activeShrine, setActiveShrine] = React.useState(11)
  const [activeShrineName, setActiveShrineName] = React.useState("The Shrine of Eden")
  const [activeShrineFee, setActiveShrineFee] = React.useState("30")
var asr = new web3.eth.Contract(nfts["AlphaShrineRouter"]["abi"], nfts["AlphaShrineRouter"]["address"])
 const checkAllowanceThenApprove = (callback = ()=>{}) => {
     candle.methods.allowance(window.ethereum.selectedAddress,nfts["AlphaShrineRouter"]["address"])
        .call({from: window.ethereum.selectedAddress})
        .then((allowance) => {
          let alw = web3.utils.fromWei(allowance, "ether")
          if (alw >= activeShrineFee) {
            callback()
          } else {
            let data = candle.methods.approve(nfts["AlphaShrineRouter"]["address"],web3.utils.toWei(activeShrineFee,"ether")).encodeABI()
            setPendingText("Approving "+activeShrineFee+" $CANDLE")
            sendRequest(tokens["candle"]["address"],data,callback)
          }
        })
  }
  const openDash = () => {
    setIsDashboardShowing(true)
    requestResources()
    requestCrystals()
    checkTokenBalances()
  }


  return (
    <div className="App">
      <div className="the-story"> 
        
    

        <div className={"dialog dialog--" + isDialogOpen}>
        <div className="dialog__active">
          <div className={
            "dialog__speaker dialog__speaker--" + story[activeDialogIndex.toString()]["imgtype"] + 
            " dialog__speaker--" + ifHasImgNameGetIt()
             }>
            <img src={story[activeDialogIndex.toString()]["speaker"]}/>
           
          
          </div>
          <div className={"dialog__text dialog__text--" + story[activeDialogIndex.toString()]["imgtype"]}>
          {
            story[activeDialogIndex.toString()]["text"].map(p=>(<p>{replaceSpeakerWithStrongSpeaker(p)}</p>))
          }
          </div>
          <div className={"dialog__options dialog__options--" + checkedPreOptions}>
          
            {
              (story[activeDialogIndex.toString()].hasOwnProperty("password")) ?
              <div className="password">
                <input ref={passwordRef} defaultValue="password" />
                <div 
                  className="dialog__option" 
                  onClick={() => {
                    if ( story[activeDialogIndex.toString()].password == "DL0" ) {
                       if (passwordRef.current.value == "Do you have the time?") {
                        setActiveDialogIndex(story[activeDialogIndex.toString()]["password"])
                       } else {
                        alert(passwordRef.current.value + " is not the correct password. Please try again.")
                       }
                    } else {
                      if (passwordRef.current.value == "241113") {
                        alert("Correct! Please type 'Who would have known the devil was such a good dancer?' into general chat in the cult discord.")
                        alert("Then type in: 'Not me, $$$' where $$$ must be a 3 digit sequence not used by anyone else. Please scroll through chat to ensure you aren't duplicating another user.")
                        alert("If you followed the instructions will receive a special role and a gift soon!")
                        setActiveDialogIndex(story[activeDialogIndex.toString()]["password"])
                      } else {
                        alert(passwordRef.current.value + " is not the correct password. Please try again.")
                      }
                    }
                    
                  }}>{"> "}Answer
                </div>
              </div> :
              
                story[activeDialogIndex.toString()]["options"].map(
                  (btn,index)=>(
                    <div 
                      key={index}
                      className={
                        "dialog__option dialog__option--" + 
                        activeDialogIndex.toString() + 
                        " dialog__option--" + index }
                      onClick={
                        () => {
                          console.log("clicked")
                          if (story[activeDialogIndex.toString()].hasOwnProperty("modal") && !isBypassed) {
                            showModal(story[activeDialogIndex.toString()]["modal"])
                          } else {
                            if (story[activeDialogIndex.toString()]["optiontarget"][index].includes("GameOver")) {
                              playGameOver()
                            } else {
                               playPageTurn()
                              
                            }
                            setActiveDialogIndex(story[activeDialogIndex.toString()]["optiontarget"][index])      
                          }                 
                        }
                        

                      }>
                      {"> " + btn}
                    </div>
                    ))
              
            
            }
            <div className="progress-bar">
              <div className="progress-bar__inside" style={{width: 2.3*story[activeDialogIndex.toString()]["key"] + '%'}}></div>
            </div>
          </div>
        
        
          <div className="dialog__nav">
            <div className="nav-btn nav-btn--left" onClick={goBack(story[activeDialogIndex.toString()]["back"])}>{"<"} Back</div>
            <div className="nav-btn nav-btn--right" onClick={showMenu}>x Exit</div>
          </div>
        </div>

      </div>
      </div>
      

      

       <div className={"store store-open--" + isStoreOpen}> 
          <div className={"store__img"}><img src={storefront} /></div>
          <div className="store__options">
            <button className="store__option" onClick={mint1155(1,1)}>
              <img className="nft-thumb" src="https://candle.farmgod.finance/static/media/apple.png" />
              <span>Apple</span>
              <span>1 $CANDLE</span>
              <span className="smaller">{Number(minted_item1)} / 1,000,000 minted</span>
            </button>
            <button className="store__option" onClick={mint1155(2,1000)}>
              <img className="nft-thumb" src="https://candle.farmgod.finance/static/media/apple_bite.png" />
              <span>Apple (..bitten)</span>
              <span>1,000 $CANDLE</span>
              <span className="smaller">{Number(minted_item2)} / 100 minted</span>
            </button>
            <button className="store__option" onClick={mint1155(11,1)}>
              <img className="nft-thumb" src="https://candle.farmgod.finance/static/media/briefcase_plain.png" />
              <span>Briefcase</span>
              <span>1 $CANDLE</span>
              <span className="smaller">{Number(minted_item11)} / 1,000,000 minted</span>
            </button>
            <button className="store__option" onClick={mint1155(12,1000)}>
              <img className="nft-thumb" src="https://candle.farmgod.finance/static/media/briefcase.png" />
              <span>Cult Briefcase</span>
              <span>1,000 $CANDLE</span>
              <span className="smaller">{Number(minted_item12)} / 100 minted</span>
            </button>
            <button className="store__option" onClick={mint1155(13,3000)}>
              <img className="nft-thumb" src="https://candle.farmgod.finance/static/media/briefcase_bone.png" />
              <span>Bone Handle Briefcase</span>
              <span>3,000 $CANDLE</span>
              <span className="smaller">{Number(minted_item13)} / 100 minted</span>
            </button>
            <button className="store__option" onClick={mint1155(21,1)}>
              <img className="nft-thumb" src="https://candle.farmgod.finance/static/media/key.png" />
              <span>Skeleton Key</span>
              <span>1 $CANDLE</span>
              <span className="smaller">{Number(minted_item21)} / 1,000,000 minted</span>
            </button>
            <button className="store__option" onClick={mint1155(22,3000)}>
              <img className="nft-thumb" src="https://candle.farmgod.finance/static/media/key_bone.png" />
              <span>Bone Key</span>
              <span>3,000 $CANDLE</span>
              <span className="smaller">{Number(minted_item22)} / 100 minted</span>
            </button>
            <button className="store__option" onClick={mint1155(31,5000)}>
              <img className="nft-thumb" src="https://candle.farmgod.finance/static/media/matchbook.png" />
              <span>Matchbook</span>
              <span>5,000 $CANDLE</span>
              <span className="smaller">{Number(minted_item31)} / 42 minted</span>
            </button>

            {
              /* 
                  <button className="store__option" onClick={mint1155(101,10)}>
                    <img className="nft-thumb" src={record} />
                    <span>Record</span>
                    <span>10 $CANDLE</span>
                    <span className="smaller">{Number(minted_item101)} / 1000 minted</span>
                  </button>

              */
            }
            
            <button className="store__option" onClick={mint1155(106,1000)} >
              <img className="nft-thumb" src={goggles} />
              <span>Goggles</span>
              <span>1000 $CANDLE</span>
              <span className="smaller">{Number(minted_item106)} / 69 minted</span>
            </button>
            <button className="store__option" onClick={mint1155(103,1)}>
              <img className="nft-thumb" src={pickaxe}  />
              <span>Pickaxe</span>
              <span>1 $CANDLE</span>
              <span className="smaller">{Number(minted_item103)} / 1,000,000 minted</span>
            </button>
            <button className="store__option" onClick={mint1155(102,100)}>
              <img className="nft-thumb" src={lantern}  />
              <span>Lantern</span>
              <span>100 $CANDLE</span>
              <span className="smaller">{Number(minted_item102)} / 4000 minted</span>
            </button>
            <button className="store__option" onClick={mint1155(104,50)}>
              <img className="nft-thumb" src={hopium}  />
              <span>Hopium</span>
              <span>50 $CANDLE</span>
              <span className="smaller">{Number(minted_item104)} / 10000 minted</span>
            </button>
            <button className="store__option" onClick={mint1155(105,1)}>
              <img className="nft-thumb" src={pipe}  />
              <span>Pipe</span>
              <span>1 $CANDLE</span>
              <span className="smaller">{Number(minted_item105)} / 1,000,000 minted</span>
            </button>
            <button className="modal__close" onClick={closeShop}>X</button>
          </div>
        </div>

        <div className={"briefcase briefcase--" + isBCopen}>
        <div className="briefcase__title">Inventory</div>
        <div className="briefcase__items">
          <div className="inv inv--items">
            <div className="briefcase__subtitle">Items</div>
            <div className="briefcase__item">
              <img src={record}/>
              <div className="inv-count">({Number(itemBals[0])}) Records</div>
            </div>
            <div className="briefcase__item">
              <img src={lantern}/>
              <div className="inv-count">({Number(itemBals[1])}) Lanterns</div>
            </div>
            <div className="briefcase__item">
              <img src={pickaxe}/>
              <div className="inv-count">({Number(itemBals[2])}) Pickaxes</div>
            </div>
            <div className="briefcase__item">
              <img src={hopium}/>
              <div className="inv-count">({Number(itemBals[3])}) Hopium</div>
            </div>
            <div className="briefcase__item">
              <div><img src={pipe}/></div>
              <div className="inv-count">({Number(itemBals[4])}) Pipes</div>
            </div>
            
            <div className="briefcase__item">
              <img src={goggles}/>
              <div className="inv-count">({Number(itemBals[5])}) Goggles</div>
            </div>
            
            
          </div>
          <div className="inv inv--relics">
            <div className="briefcase__subtitle">Resources & Relics</div>
            <div className="briefcase__item">
              <div><img src={rock}/></div>
               <div className="inv-count">({Number(balRock)}) Rocks</div>
            </div>
            <div className="briefcase__item">
              <div><img src={chalk}/></div>
              <div className="inv-count">({Number(balChalk)}) Chalk</div>
            </div>
           
          </div>
          <div className="familiars">
            <div className="lantern-img"><img src={lantern}/></div>
            <div className="familiar-count">({fs(famBal)}) Familiars</div>
          </div>
          
          
          
          
          
          <button className="modal__close" onClick={toggleBC}>X</button>
        </div>
      </div>



        <div className="header">
          <div className="home">
            <div className="home__img" onClick={toggleBC}><img src={briefcase}/></div>
            
          </div>
        </div>
      


      
      

      <div className={"lander lander--" + isLanderOpen}>
        <div className="lander-image"><img src={edenForge} /></div>

        <div className={"lander-main lander-main--" + isMenuOpen + " lander-main--mobile-" + isModalConnectOpen}>
          <div className="lander-msg sarpanch-bold">Welcome to The Devil's Lounge</div>
          <div className="lander-button"><button onClick={press2start}>Press to Start</button></div>
        </div>

        <div className="lander-social">
          <a href="https://x.com/cultnfts" target="_blank" className="social-link"><img src={iconX}/></a>
          <a href="https://t.me/+azd0MvR8_nZjMTgx" target="_blank" className="social-link"><img src={iconTelegram}/></a>
          <a href="https://discord.gg/qXUppGGnun" target="_blank" className="social-link"><img src={iconDiscord}/></a>
        </div>

        <div className="lander-copyright courier-prime-regular">
          Copyright © CULT NFTs 2024
        </div>

        <div className={"menu-container menu-container--" + isMenuOpen}>
          <div className="menu">
            <div className="menu__title">The Devil's Lounge</div>
            <button className="menu__link" onMouseOver={playHover} onMouseOut={stopHover} onClick={beginConnectFlow}>{mmBtnText}</button>
            <button className="menu__link" onMouseOver={playHover} onMouseOut={stopHover} onClick={showStory}>Campaign</button>
            <button className="menu__link" onMouseOver={playHover} onMouseOut={stopHover} onClick={showShop}>Item Shop</button>
            <button className="menu__link" onMouseOver={playHover} onMouseOut={stopHover} onClick={openDash}>Forge</button>
            <button className="menu__link" onMouseOver={playHover} onMouseOut={stopHover} onClick={showFam}>FamX</button>
            <div className="menu__version courier-prime-regular">Version 1.0.3</div>
          </div>
        </div>

      </div>
     <div className={"modal modal--connect modal--" + isModalConnectOpen}>
        <h2>Connect</h2>
        <div className="modal__inside">
          <p className="hide-on-mobile">This is intended to be a web3 experience on the Fantom network. You can choose to connect your web3 wallet or you can choose to opt out of the web3 experience.</p>
          <p className="hide-on-mobile">Do you have the time?</p>
          <p className="show-on-mobile">Please use desktop to interact with with the web3 features of this application.</p>
          <p className="show-on-mobile">We are working to bring the full web3 experience to mobile users. Select 'Bypass' to start playing the campaign.</p>
          <div className="buttons">
            <button className="hide-on-mobile" onClick={connectMM}>Connect Web3</button>
            <button onClick={bypassConnect}>Bypass</button>
          </div>
        </div>

        <button className="modal__close" onClick={
          ()=>{
            setIsModalConnectOpen(false)
          }

        }>x</button>


      </div>

      <div className={"modal modal--mine modal--" + isModalMineOpen}>
        <h2>Mine Rock</h2>
        <div className="modal__inside">
          
          <p>Begin mining rock.</p>
          <div className="modal__img">
            <img src={rock} />
          </div>
          <div className="buttons">
            <button onClick={mineRock}>Mine</button>
            <button onClick={bypassMine}>Bypass</button>
          </div>
        </div>

        <button className="modal__close" onClick={
          ()=>{
            setIsModalMineOpen(false)
          }

        }>x</button>


      </div>

      <div className={"modal modal--mineRest modal--" + isModalMineRestOpen}>
        <h2>Finish mining rock</h2>
        <div className="modal__inside">
          
          <p>Take a rest.</p>
          <div className="modal__img">
            <img src={rest} />
          </div>
          <div className="buttons">
            <button onClick={mineRest}>Rest</button>
            <button onClick={bypassRest}>Ignore</button>
          </div>
        </div>

        <button className="modal__close" onClick={
          ()=>{
            setIsModalMineRestOpen(false)
          }

        }>x</button>


      </div>


      <div className={"modal modal--craft modal--" + isModalCraftOpen}>
        <h2>Craft Ritual Chalk</h2>
        <div className="modal__inside">
          
          <p>Begin crafting chalk.</p>
          <div className="modal__img">
            <img src={chalkMachine} />
          </div>
          <div className="buttons">
            <button onClick={craftChalk}>Craft</button>
       
          </div>
        </div>

        <button className="modal__close" onClick={
          ()=>{
            setIsModalCraftOpen(false)
          }

        }>x</button>


      </div>

       <div className={"modal modal--apple modal--" + isModalAppleOpen}>
        <h2>Mint an Apple</h2>
        <div className="modal__inside">
          
          <p>This is an optional item.</p>
          <div className="modal__img">
            <img src={apple} />
          </div>
          <div className="buttons">
            <button onClick={mintApple}>Take the Apple</button>
            <button onClick={bypassApple}>Ignore</button>
          </div>
        </div>

        <button className="modal__close" onClick={
          ()=>{
            setIsModalAppleOpen(false)
          }

        }>x</button>


      </div>

      <div className={"modal modal--pickaxe modal--" + isModalPickaxeOpen}>
        <h2>Mint a Pickaxe</h2>
        <div className="modal__inside">
          
          <p>This is an optional collectible item.</p>


          <div className="modal__img">
            <img src={pickaxe} />
          </div>
          <div className="buttons">
            <button onClick={mintPickaxe}>Take the Pickaxe</button>
            <button onClick={bypassPickaxe}>Ignore</button>
          </div>
        </div>

        <button className="modal__close" onClick={
          ()=>{
            setIsModalPickaxeOpen(false)
          }

        }>x</button>


      </div>

      <div className={"modal modal--pipe modal--" + isModalPipeOpen}>
        <h2>Mint a Pipe</h2>
        <div className="modal__inside">
          
          <p>This is an optional collectible item.</p>
          <div className="modal__img">
            <img src={pipe} />
          </div>
          <div className="buttons">
            <button onClick={mintPipe}>Take the Pipe</button>
            <button onClick={bypassPipe}>Ignore</button>
          </div>
        </div>

        <button className="modal__close" onClick={
          ()=>{
            setIsModalPickaxeOpen(false)
          }

        }>x</button>


      </div>

      <div className={"modal modal--hopium modal--" + isModalHopiumOpen}>
        <h2>Mint a jar of Hopium</h2>
        <div className="modal__inside">
          <p>This is an optional collectible item.</p>
          <div className="modal__img">
            <img src={hopium} />
          </div>
          <div className="buttons">
            <button onClick={mintHopium}>Take the Hopium</button>
            <button onClick={bypassHopium}>Ignore</button>
          </div>
        </div>
        <button className="modal__close" onClick={
          ()=>{
            setIsModalHopiumOpen(false)
          }
        }>x</button>
      </div>

      <div className={"modal modal--record modal--" + isModalRecordOpen}>
        <h2>Mint a Record</h2>
        <div className="modal__inside">
        <p>This is an optional collectible item. You will burn 10 $CANDLE when minting.</p>
          <div className="modal__img">
            <img src={record} />
          </div>
          
          <div className="buttons">
            <button onClick={mintRecord}>Mint the Record</button>
            <button onClick={bypassRecord}>Ignore</button>
          </div>
        </div>

        <button className="modal__close" onClick={
          ()=>{
            setIsModalRecordOpen(false)
          }

        }>x</button>


      </div>

      <div className={"modal modal--fam modal--" + isModalFamOpen}>
        <h2>Familiars</h2>
        <div className="modal__inside">
          <p>Familiars are an experimental ERC404 token. You can purchase an identified one from <a href="https://famx.farmgod.finance" target="_blank">FamX</a> or purchase unidentified Familiars from <a href="https://spooky.fi" target="_blank">Spooky</a>.</p>
          {
            // <p>You can also purchase one from the liquidity pool on spooky by pressing the adopt button below.</p>
          }
          <div className="modal__img">
            <img src={rest} />
          </div>
          
          <div className="buttons">
            {
              // <button onClick={adoptFam}>Adopt</button>
            }
            <button onClick={bypassFam}>Ignore</button>
          </div>
        </div>

        <button className="modal__close" onClick={
          ()=>{
            setIsModalFamOpen(false)
          }

        }>x</button>


      </div>

      <div className={"screen screen--dashboard screen-showing--" + isDashboardShowing}>
          <div className="close-screen" onClick={closeDash}>
            <div>x</div>
          </div>
          <div className="dashboard">
            <div className="dashboard__image">
              <img src={tokenImg__fam} />
            </div>

            <div className="dashboard__inventory">
              <div className="dashboard__row">
                <p className="dashboard__rowtitle">Mine Resources</p>
                <DashItem 
                  src={resource__karma} 
                  bal={balResources[0]} 
                  onClick={mine(0)} />
                <DashItem 
                  src={resource__dust} 
                  bal={balResources[1]} 
                  onClick={mine(1)} />
                <DashItem 
                  src={resource__rock} 
                  bal={balResources[2]} 
                  onClick={mine(2)} />

              </div>
              <div className="dashboard__row">
                <p className="dashboard__rowtitle">Forge Crystals and Relics</p>
                <DashItem 
                  src={crystal__karma} 
                  bal={balCrystals[0]} 
                  onClick={forge(0)} />
                <DashItem 
                  src={crystal__fam} 
                  bal={balShrineCrystals} 
                  onClick={forge(1)} />
                <DashItem 
                  src={crystal__chalk} 
                  bal={balCrystals[1]} 
                  onClick={forge(2)} />
              </div>

              <div className="dashboard__row">
                <p className="dashboard__rowtitle">Token Balances</p>
                <DashItem 
                  src={tokens["cult"]["icon"]} 
                  bal={f.format(web3.utils.fromWei(balCult, "ether"))} 
                  onClick={()=>{

                  }} />
                <DashItem 
                  src={tokens["xcult"]["icon"]} 
                  bal={f.format(web3.utils.fromWei(balXCult, "ether"))} 
                  onClick={()=>{
                    
                  }} />
                <DashItem 
                  src={tokens["candle"]["icon"]} 
                  bal={Math.round(web3.utils.fromWei(balCandle, "ether"))} 
                  onClick={()=>{
                    
                  }} />
                <DashItem 
                  src={tokenImg__fam} 
                  bal={f.format(web3.utils.fromWei(balFam, "ether"))} 
                  onClick={()=>{
                    
                  }} />
              </div>
            </div>
          </div>
        </div>
 <MineModal
          name="Karma"
          bal={balResources[0]}
          time="1 Block"
          delay="10,000 Blocks"
          weights="[1,1,...,1]"
          maxes="[1,1,...,1]"
          note="Karma is used to forge Karma Crystals"
          img={resource__karma}
          isShowing={isMineShowing__Karma}
          closeScreen={closeMine__Karma}
          status={status}
          mine={startMining(1)}
          rest={rest} 
          refresh={checkStatus}/>

        <MineModal
          name="Familiar Dust"
          bal={balResources[1]}
          time="1 Block"
          delay="1000 Blocks"
          weights="Familiars Only"
          maxes="No Maxes"
          note="Familiar Dust is used to forge Familiar Crystals"
          fambal={Math.floor(web3.utils.fromWei(balFam, "ether"))}
          img={resource__dust}
          isShowing={isMineShowing__Dust}
          closeScreen={closeMine__Dust}
          statusFD={statusFD}
          mine={startMiningFD}
          rest={restFD}
          refresh={checkStatusFD}
          fdfbal={fdfBal} />

        <MineModal
          name="Sacred Rock"
          bal={balResources[2]}
          time="1 Block"
          delay="1 Block"
          weights="[10,1,...,1]"
          maxes="[5,5,...,5]"
          note="Rock is mined in Eden in Chapter 1 of the Campaign. It is used to craft Ritual Chalk."
          img={resource__rock}
          isShowing={isMineShowing__Rock}
          closeScreen={closeMine__Rock}
          status={status}
          mine={startMining(3)}
          rest={rest}
          refresh={checkStatus}/>

          <ForgeModal
            name="Karma Crystal"
            bal={balCrystals[0]}
            resbal={balResources[0]}
            img={crystal__karma}
            isShowing={isForgeShowing__Karma}
            closeScreen={closeForge__Karma}
            resource="Karma"
            rate="32"
            shrine="No"
            note=""
            inputRef={crystalRef__karma}
            forge={startForging(1)}

          />

          <ForgeModal
            name="Familiar Crystal"
            bal={balShrineCrystals}
            resbal={balResources[1]}
            img={crystal__fam}
            isShowing={isForgeShowing__Fam}
            closeScreen={closeForge__Fam}
            resource="Familiar Dust"
            rate="32"
            shrine="Yes"
            note=""
            activeShrine={activeShrine}
            activeShrineName={activeShrineName}
            activeShrineFee={activeShrineFee}
            enter={startForgingShrine}
            leave={finishForgingShrine}
          />

          <ForgeModal
            name="Ritual Chalk"
            bal={balCrystals[1]}
            resbal={balResources[2]}
            img={crystal__chalk}
            isShowing={isForgeShowing__Chalk}
            closeScreen={closeForge__Chalk}
            resource="Rock"
            rate="8"
            shrine="No"
            note=""
            inputRef={crystalRef__chalk}
            forge={startForging(11)}
          />
      

      <div className={"modal modal--pending modal--" + isPending}>
        <h2>Pending Transaction</h2>
        <div className="modal__inside">
        <p>{pendingText}</p>
          <div className="modal__img">
            <img src={pending} />
          </div>
          
          
        </div>

        <button className="modal__close" onClick={
          ()=>{
            setIsPending(false)
          }

        }>x</button>

      </div>

      <audio ref={soundRef__keychain}>
        <source src={sound__keychain} type="audio/wav" />
        Your browser does not support the audio element.
      </audio>

      <audio ref={soundRef__page}>
        <source src={sound__click} type="audio/wav" />
        Your browser does not support the audio element.
      </audio>

      <audio ref={soundRef__adopt}>
        <source src={sound__adopt} type="audio/wav" />
        Your browser does not support the audio element.
      </audio>

      <audio ref={soundRef__menu}>
        <source src={sound__menuMusic3} type="audio/mp3" />
        Your browser does not support the audio element.
      </audio>

      <audio ref={soundRef__gameOver}>
        <source src={sound__gameOver} type="audio/wav" />
        Your browser does not support the audio element.
      </audio>

      <audio ref={soundRef__crystal}>
        <source src={sound__crystal} type="audio/wav" />
        Your browser does not support the audio element.
      </audio>

      <audio ref={soundRef__mining}>
        <source src={sound__mining} type="audio/wav" />
        Your browser does not support the audio element.
      </audio>

      <audio ref={soundRef__hover}>
        <source src={sound__hover} type="audio/wav" />
        Your browser does not support the audio element.
      </audio>



    </div>
  );
}

export default App;
