export const tokens = {
	"cult": {
		"name": "CULT",
		"address": "0x4Ed9C2aba77141433c462B8EE835B7fC39Ec449A",
		"icon": require("../images/token-icons/cult.jpg_medium"),
		"abi": require("../abis/cult.json")
	}, 
	"xcult": {
		"name": "xCULT",
		"address": "0x718670c88f2daa222d44908c1c253253ff96ae21",
		"icon": require("../images/token-icons/x.png"),
		"abi": require("../abis/xcult.json")
	}, 
	"candle": {
		"name": "CANDLE",
		"address": "0x208234F4f8B1bBEA59cfDc38666141654e851DCe",
		"icon": require("../images/token-icons/candle.jpg_small"),
		"abi": require("../abis/GenericABI.json"),
		"x": 0x208234F4f8B1bBEA59cfDc38666141654e851DCe
	}, 
	"soul": {
		"name": "SOUL",
		"address": "0xe2fb177009FF39F52C0134E8007FA0e4BaAcBd07",
		"icon": require("../images/token-icons/soul.png"),
		"abi": require("../abis/GenericABI.json"),
		"x": 0xe2fb177009FF39F52C0134E8007FA0e4BaAcBd07
	},
	"wftm": {
		"name": "WFTM",
		"address": "0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83",
		"icon": require("../images/token-icons/FTM.png"),
		"abi": require("../abis/GenericABI.json"),
		"x": 0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83
	},
	"ftm": {
		"name": "FTM",
		"address": "0x0000000000000000000000000000000000000000",
		"icon": require("../images/token-icons/FTM.png"),
		"abi": require("../abis/GenericABI.json")
	},
	"cult-soul": {
		"name": "CULT-SOUL SoulSwap LP",
		"address": "0x4AB8b967F75cf860b27Ebd1F6a174aD440F754FC",
		"tokens": ["cult","soul"],
		"dex": "SoulSwap",
		"icon": require("../images/token-icons/cult.jpg_medium"),
		"abi": require("../abis/GenericABI.json")
	},
	"xcult-soul": {
		"name": "xCULT-SOUL SoulSwap LP",
		"address": "0xc8Ad1928ba95f29f967d6CB2E232Fd722C4089B2",
		"tokens": ["xcult","soul"],
		"dex": "SoulSwap",
		"icon": require("../images/token-icons/x.png"),
		"abi": require("../abis/GenericABI.json")
	},
	"candle-soul": {
		"name": "CANDLE-SOUL SoulSwap LP",
		"address": "0xf58bc729013FD106619241052a5182C06D948edA",
		"tokens": ["candle","soul"],
		"dex": "SoulSwap",
		"icon": require("../images/token-icons/candle.jpg_small"),
		"abi": require("../abis/GenericABI.json")
	},
	"wftm-usdc": {
		"name": "WFTM-USDC Spooky LP",
		"address": "0x2b4C76d0dc16BE1C31D4C1DC53bF9B45987Fc75c",
		"tokens": ["wftm","usdc"],
		"dex": "Spooky",
		"icon": require("../images/token-icons/candle.jpg_small"),
		"abi": require("../abis/GenericABI.json")
	},
	"candle-usdc": {
		"name": "CANDLE-USDC Spooky LP",
		"address": "0xd5300D3CEbA9a3C491dD3220a7a72ecFcd7dD755",
		"tokens": ["usdc","candle"],
		"dex": "Spooky",
		"icon": require("../images/token-icons/candle.jpg_small"),
		"abi": require("../abis/GenericABI.json")
	},
	"usdc": {
		"name": "USDC",
		"address": "0x04068DA6C83AFCFA0e13ba15A6696662335D5B75",
		"icon": require("../images/token-icons/usdc.png"),
		"abi": require("../abis/GenericABI.json")
	},
	"xcult-ftm": {
		"name": "xCULT-FTM Spooky LP",
		"address": "0x41533a954de896822FB29dB8dACA5501557F0b65",
		"tokens": ["wftm","xcult"],
		"dex": "Spooky",
		"icon": require("../images/token-icons/candle.jpg_small"),
		"abi": require("../abis/GenericABI.json")
	},
	"cult-ftm": {
		"name": "CULT-FTM Spooky LP",
		"address": "0xF8C5187294EbD7eCdCD9Cb9E7B2BCb46a3420288",
		"tokens": ["wftm","cult"],
		"dex": "Spooky",
		"icon": require("../images/token-icons/candle.jpg_small"),
		"abi": require("../abis/GenericABI.json")
	}, 
	"cult-xcult": {
		"name": "CULT-xCULT Spooky LP",
		"address": "0x958eF066f6668c2D146229a6bad3E27De298bDE6",
		"tokens": ["cult","xcult"],
		"dex": "Spooky",
		"icon": require("../images/token-icons/candle.jpg_small"),
		"abi": require("../abis/GenericABI.json")
	},
	"fam": {
		"name": "Familiars",
		"address": "0x6EB7847E92D5178A1B48f9030DEae7e62D367520",
		"icon": require("../images/token-icons/candle.jpg_small"),
		"abi": require("../abis/GenericABI.json")
	}
}

export const lps = {
	"cult-soul": {
		"name": "CULT-SOUL SoulSwap LP",
		"address": "0x4AB8b967F75cf860b27Ebd1F6a174aD440F754FC",
		"tokens": ["cult","soul"],
		"abi": require("../abis/soulLP.json"),
		"dex": "SoulSwap"
	},
	"xcult-soul": {
		"name": "xCULT-SOUL SoulSwap LP",
		"address": "0xc8Ad1928ba95f29f967d6CB2E232Fd722C4089B2",
		"tokens": ["xcult","soul"],
		"abi": require("../abis/soulLP.json"),
		"dex": "SoulSwap"
	},
	"candle-soul": {
		"name": "CANDLE-SOUL SoulSwap LP",
		"address": "0xf58bc729013FD106619241052a5182C06D948edA",
		"tokens": ["candle","soul"],
		"abi": require("../abis/soulLP.json"),
		"dex": "SoulSwap"
	}, 
	"soul-wftm": {
		"name": "SOUL-WFTM SoulSwap LP",
		"address": "0xa2527Af9DABf3E3B4979d7E0493b5e2C6e63dC57",
		"tokens": ["soul","wftm"],
		"abi": require("../abis/soulLP.json"),
		"dex": "SoulSwap"
	},
	"soul-usdc": {
		"name": "SOUL-USDC SoulSwap LP",
		"address": "0xC0A301f1E5E0Fe37a31657e8F60a41b14d01B0Ef",
		"tokens": ["usdc","soul"],
		"abi": require("../abis/soulLP.json"),
		"dex": "SoulSwap"
	},
	"wftm-usdc": {
		"name": "WFTM-USDC Spooky LP",
		"address": "0x2b4C76d0dc16BE1C31D4C1DC53bF9B45987Fc75c",
		"tokens": ["usdc","wftm"],
		"dex": "Spooky",
		"abi": require("../abis/spookyLP.json")
	},
	"candle-usdc": {
		"name": "CANDLE-USDC Spooky LP",
		"address": "0xd5300D3CEbA9a3C491dD3220a7a72ecFcd7dD755",
		"tokens": ["usdc","candle"],
		"dex": "Spooky",
		"abi": require("../abis/spookyLP.json")
	},
	"xcult-ftm": {
		"name": "xCULT-FTM Spooky LP",
		"address": "0x41533a954de896822FB29dB8dACA5501557F0b65",
		"tokens": ["xcult","wftm"],
		"dex": "Spooky",
		"icon": require("../images/token-icons/candle.jpg_small"),
		"abi": require("../abis/spookyLP.json")
	},
	"cult-ftm": {
		"name": "CULT-FTM Spooky LP",
		"address": "0xF8C5187294EbD7eCdCD9Cb9E7B2BCb46a3420288",
		"tokens": ["cult","wftm"],
		"dex": "Spooky",
		"icon": require("../images/token-icons/candle.jpg_small"),
		"abi": require("../abis/spookyLP.json")
	}, 
	"cult-xcult": {
		"name": "CULT-xCULT Spooky LP",
		"address": "0x958eF066f6668c2D146229a6bad3E27De298bDE6",
		"tokens": ["cult","xcult"],
		"dex": "Spooky",
		"icon": require("../images/token-icons/candle.jpg_small"),
		"abi": require("../abis/spookyLP.json")
	}
}

export const farms = {
	"burn": {
		"address": "0xc450a27A9BDAB9557304C8cF8C71f18Afd2ba642",
		"abi": require("../abis/MatchbookFarms.json")
	},
	"gate": {
		"address": "0xACc1c939b42A1a0e68C2975aB7B46BD8D168126C",
		"abi": require("../abis/CandleGatedChef.json")
	}, 
	"degenesis": {
		"address": "0x2462FA2Bc4B0Cbc501c8546081556fF4F84162De",
		"abi": require("../abis/CandleDegenesisABI.json")
	}
}

export const routers = {
	"SoulSwap": {
		"address": "0x6b3d631B87FE27aF29efeC61d2ab8CE4d621cCBF",
		"abi": require("../abis/soulrouter.json")
	}, // 0xF491e7B69E4244ad4002BC14e878a34207E38c29
	"Spooky": {
		"address": "0xF491e7B69E4244ad4002BC14e878a34207E38c29",
		"abi": require("../abis/soulrouter.json")
	}
}